import {Box, Flex, Select, Skeleton, Tooltip, useToast} from "@chakra-ui/react";
import React, {useMemo, useState} from "react";
import useRequest from "../../../hooks/RequestHook";
import ServerService from "../../../services/ServerService";
import {shortUserName} from "../../../utils/environments";
import {WarningIcon} from "@chakra-ui/icons";

export default function ScorecardTeacherSelect({scorecard, onUpdate = scorecard => undefined, isDisabled = false, ...props}) {
    //initial components
    const toast = useToast()

    //initial requests
    const patchScorecardRequest = useRequest(ServerService.patchScorecard, {
        defaultConfig: {
            id: scorecard["_id"]
        },
        onSuccess: () => toast({
            title: `Данные о преподавателе обновлены`,
            position: 'top-right',
            status: 'success',
            isClosable: true,
        }),
        onError: () => toast({
            title: `Ошибка обновления данных о преподавателе`,
            position: 'top-right',
            status: 'error',
            isClosable: true,
        })
    })

    //initial actions
    const onTeacherSelected = teacher => {
        const updatedScorecard = {...scorecard, teacher}
        const data = {teacher: teacher}

        patchScorecardRequest.start(
            {data},
            {
                onSuccessCallBack: () => onUpdate(updatedScorecard)
            }
        )
    }

    //initial props
    const teacherUserIdSelected = useMemo(() => scorecard['teacher']['userId'], [scorecard])
    const teachers = useMemo(() => scorecard['fksGroup']['teachers'], [scorecard])

    //build view
    return (
        <Flex onClick={event => event.stopPropagation()} alignItems="center" {...props}>
            <Skeleton isLoaded={!patchScorecardRequest.isLoading} fadeDuration={1} minWidth={'100%'} rounded='sm'>
                <Box position="relative" minWidth={'100%'}>
                    <Select
                        size='sm'
                        value={teacherUserIdSelected}
                        isDisabled={isDisabled || (teachers?.length <= 1 ?? true)}
                        onChange={event => {
                            const teacherUserId = event.target.value
                            const teacher = teachers.find(teacher => teacher['userId'] === teacherUserId)
                            onTeacherSelected(teacher)
                        }}>
                        {teachers?.map(teacher =>
                            <option key={teacher["userId"]} value={teacher["userId"]}>
                                {shortUserName(teacher)}
                            </option>
                        )}
                    </Select>
                    {patchScorecardRequest.error &&
                        <Box position="absolute" top="0" bottom="0" right="100%" width="max-content" height="max-content" marginX={2} marginY="auto">
                            <Tooltip label="Ошибка обновления поля" placement="left">
                                <WarningIcon boxSize={4} color="red"/>
                            </Tooltip>
                        </Box>
                    }
                </Box>
            </Skeleton>
        </Flex>
    )
}
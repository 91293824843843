import React from 'react';
import {Icon} from "@chakra-ui/react";

const StudyIcon = (props) => (
    <Icon xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" strokeWidth="2" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round" {...props}>
        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
        <path d="M22 9l-10 -4l-10 4l10 4l10 -4v6"/>
        <path d="M6 10.6v5.4a6 3 0 0 0 12 0v-5.4"/>
    </Icon>
)

export default StudyIcon;
import React from 'react';
import {Icon} from "@chakra-ui/react";

const StatisticIcon = (props) => (
    <Icon xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" strokeWidth="2" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round" {...props}>
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <rect x="3" y="4" width="18" height="12" rx="1"/>
            <line x1="7" y1="20" x2="17" y2="20"/>
            <line x1="9" y1="16" x2="9" y2="20"/>
            <line x1="15" y1="16" x2="15" y2="20"/>
            <path d="M8 12l3 -3l2 2l3 -3"/>
    </Icon>
)

export default StatisticIcon;
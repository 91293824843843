import React, {useContext, useMemo, useState} from 'react';
import {Badge, Box, IconButton, Table, TableContainer, Tbody, Td, Th, Thead, Tooltip, Tr, useDisclosure} from "@chakra-ui/react";
import {collectPaginationSerialNumber} from "../utils/pagination";
import {isHealthCertificateValid} from "../utils/environments";
import ScorecardTeacherSelect from "../fragments/journal/components/ScorecardTeacherSelect";
import {SessionContext} from "../context/SessionContext";
import {PaginationContext} from "../context/PaginationContext";
import RemoveIcon from "./icons/RemoveIcon";
import DeleteScorecardModal from "../fragments/journal/components/DeleteScorecardModal";

export default function ScorecardTable2({scorecards = [], onUpdate = scorecards => undefined, onSelect = scorecard => undefined, ...props}) {
    //initial states
    const [scroll, setScroll] = useState(0)

    //initial actions
    const onUpdateItem = scorecard => {
        onUpdate(scorecards =>
            scorecards.map(sc =>
                sc['_id'] === scorecard['_id'] ? scorecard : sc
            )
        )
    }
    const onRemoveItem = scorecard => {
        onUpdate(scorecards =>
            scorecards.filter(_scorecard => _scorecard['_id'] !== scorecard['_id'])
        )
    }

    //initial props
    const columnProps = useMemo(() => ({
        paddingX: "1",
        paddingY: '4',
        textAlign: 'center',
        color: 'gray.500',
        fontSize: 'xs',
        fontWeight: 'normal',
        userSelect: 'none'
    }), [])

    //build view
    return (
        <TableContainer
            overflowX={'hidden'} overflowY={'auto'}
            onScroll={event => setScroll(event.target['scrollTop'])}
            background={'white'}
            border={'1px'} borderColor={'gray.200'} borderRadius={'md'}
            {...props}>
            <Table>
                <Thead
                    position={'sticky'} top={'0'} zIndex={'100'}
                    backgroundColor={'gray.50'}
                    boxShadow={scroll > 0 ? 'base' : 'none'}
                    transition={'ease 200ms'}>
                    <Tr>
                        <Th {...columnProps} >№</Th>
                        <Th {...columnProps} textAlign={'start'}>Фамилия Имя Отчество</Th>
                        <Th {...columnProps} >Факультет</Th>
                        <Th {...columnProps} >Группа</Th>
                        <Th {...columnProps} >Справка</Th>
                        <Th {...columnProps} >Наличие зачета</Th>
                        <Th {...columnProps} >Преподаватель</Th>
                        <Th {...columnProps} ></Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {scorecards?.map((scorecard, index) => (
                        <ScorecardTable2Item
                            key={scorecard['_id']}
                            scorecard={scorecard}
                            onUpdate={onUpdateItem}
                            onRemove={onRemoveItem}
                            onSelect={onSelect}
                            index={index}/>
                    ))}
                </Tbody>
            </Table>
        </TableContainer>
    )
}

function ScorecardTable2Item({scorecard, onUpdate = scorecard => undefined, onRemove = scorecard => undefined, onSelect = scorecard => undefined, index = 0, ...props}) {
    //initial contexts
    const session = useContext(SessionContext)
    const pagination = useContext(PaginationContext)

    //initial states
    const removeScorecardModalController = useDisclosure()

    //initial props
    const columnProps = useMemo(() => ({
        paddingX: "1",
        paddingY: "1.5",
        color: "gray.500",
        fontSize: "sm",
        textAlign: 'center'
    }), [])

    //build view
    return (
        <>
            <Tr
                onClick={() => onSelect(scorecard)}
                cursor={'pointer'}
                _hover={{background: 'gray.50'}}
                {...props}>
                <Td {...columnProps} color={'gray.400'}>{collectPaginationSerialNumber(pagination, index + 1)}</Td>
                <Td {...columnProps} textAlign='start' fontWeight='medium'>{scorecard['student']["fullName"]}</Td>
                <Td {...columnProps}>{scorecard['student']["faculty"]}</Td>
                <Td {...columnProps}>{scorecard['student']["group"]}</Td>
                <Td {...columnProps}>
                    {isHealthCertificateValid(scorecard['healthCertificate'])
                        ? <Badge minWidth={'100%'} colorScheme='green' variant='outline'>Действительна</Badge>
                        : <Badge minWidth={'100%'} colorScheme='red' variant='solid'>Отсутствует</Badge>
                    }
                </Td>
                <Td {...columnProps}>
                    {scorecard['creditIsPassed']
                        ? <Badge minWidth={'100%'} size='sm' colorScheme='green' variant='solid'>Получен</Badge>
                        : <Badge minWidth={'100%'} colorScheme='gray' variant='outline'>Отсутствует</Badge>
                    }
                </Td>
                <Td {...columnProps}>
                    <ScorecardTeacherSelect
                        scorecard={scorecard}
                        onUpdate={onUpdate}
                        isDisabled={session['role'] !== 'Admin'}
                        minWidth={'100%'}/>
                </Td>
                <Td {...columnProps}>
                    <Box>
                        <Tooltip label={'Удалить учебную карту'}>
                            <IconButton
                                role={'group'}
                                variant={'unstyled'}
                                icon={<RemoveIcon stroke={'gray.200'} transition={'250ms ease'} _groupHover={{stroke: 'red.500'}}/>}
                                aria-label={'Удалить учебную карту'}
                                onClick={event => {
                                    event.stopPropagation()
                                    removeScorecardModalController.onOpen()
                                }}/>
                        </Tooltip>
                        {removeScorecardModalController.isOpen &&
                            <DeleteScorecardModal
                                controller={removeScorecardModalController}
                                scorecard={scorecard}
                                onDelete={onRemove}/>
                        }
                    </Box>
                </Td>
            </Tr>
        </>
    )
}
import React from 'react';
import {Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text} from "@chakra-ui/react";
import SearchTeacherAutocomplete from "../../../components/SearchTeacherAutocomplete";

export default function SearchTeacherModal({controller, onSelect = (teacher) => undefined, ...props}) {
    //initial actions
    const onSelectTeacherAction = teacher => {
        onSelect(teacher)
        controller?.onClose()
    }

    //initial view
    return <Modal isOpen={controller?.isOpen} onClose={controller?.onClose} {...props}>
        <ModalOverlay/>
        <ModalContent>
            <ModalHeader>Поиск преподавателя</ModalHeader>
            <ModalCloseButton/>
            <ModalBody>
                <SearchTeacherAutocomplete onSelect={onSelectTeacherAction}/>
            </ModalBody>
            <ModalFooter>
                <Text color="gray.300" fontSize={'sm'}>Выберите преподавателя из выпадающего списка</Text>
            </ModalFooter>
        </ModalContent>
    </Modal>
}
import {Badge, Box, Button, Flex, Spinner, Stack, Text, useToast} from "@chakra-ui/react";
import * as moment from "moment-ru";
import React from "react";
import useRequest from "../../../hooks/RequestHook";
import ServerService from "../../../services/ServerService";
import {isHealthCertificateValid} from "../../../utils/environments";

export default function StudentScorecard({scorecard, onUpdate, ...props}) {
    //initial components
    const toast = useToast()

    //initial props
    const visit = scorecard['attendance'].find(({date}) => moment().isSame(moment(date), 'day'))
    const presence = visit?.presence ?? 'Отсуствовал'
    const color = presence === 'Присутствовал' ? 'green.300' : presence === 'Ув. Причина' ? 'orange.300' : 'gray.300'

    //initial requests
    const patchScorecardRequest = useRequest(ServerService.patchScorecard, {
        defaultConfig: {
            id: scorecard['_id']
        },
        onSuccess: () => {
            toast({
                title: scorecard['student']['name'],
                description: 'Данные обновлены',
                position: 'top-right',
                status: 'success',
                duration: '1250',
                isClosable: true,
            })
        },
        onError: () => {
            toast({
                title: scorecard['student']['name'],
                description: `Ошибка обновления данных`,
                position: 'top-right',
                status: 'error',
                duration: '1250',
                isClosable: true,
            })
        }
    })

    //initial actions
    const onPresenceChangeAction = presence => {
        let newScorecard

        if (visit) {
            const newAttendance = scorecard['attendance'].map(_visit => _visit === visit ? {...visit, presence} : _visit)
            newScorecard = {...scorecard, attendance: newAttendance}
        } else {
            const newAttendance = scorecard['attendance'].map(_visit => _visit)
            newScorecard = {...scorecard, attendance: [...newAttendance, {date: moment().format('YYYY-MM-DD'), grade: 0, presence}]}
        }

        const data = {attendance: newScorecard['attendance']}

        patchScorecardRequest.start({data}, {
            onSuccessCallBack: () => onUpdate && onUpdate(newScorecard)
        })
    }
    const onPresenceRemoveAction = () => {
        const newAttendance = scorecard['attendance'].filter(_visit => _visit !== visit)
        const newScorecard = {...scorecard, attendance: newAttendance}

        const data = {attendance: newScorecard['attendance']}

        patchScorecardRequest.start({data}, {
            onSuccessCallBack: () => onUpdate && onUpdate(newScorecard)
        })
    }

    //initial view
    return <Flex
        alignItems={'stretch'}
        background={'white'}
        border={'1px'} borderColor={color} borderRadius={'md'}
        cursor={'pointer'}
        _hover={{
            background: 'gray.100'
        }}
        {...props}>
        <Box
            width={'5px'}
            background={color}/>
        <Flex
            flex={'1 1 0'}
            paddingX={'5'}
            paddingY={'3'}
            flexDirection={{base: 'column', md: 'row'}}
            columnGap={'10'}
            rowGap={'3'}
            alignItems={{base: 'start', md: 'center'}}
            justifyContent={'space-between'}>
            <Stack spacing={'0'} flex={'1 1 0'}>
                <Text color={'gray.400'} fontSize={'12px'}>
                    {`${scorecard['student']['faculty']} - ${scorecard['student']['group']} - ${scorecard['student']['course']} курс - ${scorecard['student']['typeOfEducation']}`}
                </Text>
                <Text color={'#00487a'} fontSize={'18px'} fontWeight={'medium'}>
                    {scorecard['student']['fullName']}
                </Text>
                <Text color={'gray.500'} fontSize={'14px'}>
                    {scorecard['student']['specialty']}
                </Text>
            </Stack>
            <Stack spacing={'2'} flex={'0 1 0'}>
                {isHealthCertificateValid(scorecard['healthCertificate'])
                    ? <Badge colorScheme='green' variant='outline'><Text textAlign={'center'}>Справка действительна</Text></Badge>
                    : <Badge colorScheme='red' variant='solid'><Text textAlign={'center'}>Справка отсутствует</Text></Badge>
                }
                {scorecard['creditIsPassed']
                    ? <Badge size='sm' colorScheme='green' variant='solid'><Text textAlign={'center'}>Зачет получен</Text></Badge>
                    : <Badge colorScheme='gray' variant='outline'><Text textAlign={'center'}>Зачет отсутствует</Text></Badge>
                }
            </Stack>
            <Stack spacing={'2'} flex={'0 0 135px'}>
                {patchScorecardRequest.isLoading
                    ? (
                        <Spinner
                            margin={'auto'}
                            thickness='5px'
                            speed='0.65s'
                            emptyColor='gray.200'
                            color='gray.500'
                            size='md'/>
                    )
                    : (
                        presence === 'Отсуствовал'
                            ? <>
                                <Button
                                    height={'28px'}
                                    fontSize='12px'
                                    colorScheme='whatsapp'
                                    variant='outline'
                                    size={'sm'}
                                    onClick={event => {
                                        event.stopPropagation()
                                        onPresenceChangeAction('Присутствовал')
                                    }}>
                                    Присутствовал
                                </Button>
                                <Button
                                    height={'28px'}
                                    fontSize='12px'
                                    colorScheme='orange'
                                    variant='outline'
                                    size={'sm'}
                                    onClick={event => {
                                        event.stopPropagation()
                                        onPresenceChangeAction('Ув. Причина')
                                    }}>
                                    Ув. Причина
                                </Button>
                            </>
                            : <Button
                                colorScheme='gray'
                                variant='solid'
                                size={'sm'}
                                onClick={event => {
                                    event.stopPropagation()
                                    onPresenceRemoveAction()
                                }}>
                                Отменить запись
                            </Button>

                    )
                }
            </Stack>
        </Flex>
    </Flex>
}
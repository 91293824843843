import React, {useState} from "react";
import {Box, Button, Flex, FormControl, FormHelperText, FormLabel, Input, Stack, useToast} from "@chakra-ui/react";
import useRequest from "../hooks/RequestHook";
import ServerService from "../services/ServerService";
import {sha1} from "../utils/sha1";

export default function LoginScreen() {
    const toast = useToast()

    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')

    const authorizationRequest = useRequest(ServerService.login, {
        onSuccess: (response, data) => window.location.reload(),
        onError: event => {
            toast({
                title: 'Ошибка авторизации',
                description: event.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'top'
            })
        }
    })

    const onSubmitAction = () => {
        if (login.length > 0 && password.length > 0) {
            const loginForm = new FormData()
            loginForm.append('login', login)
            loginForm.append('passwordHash', sha1(password))

            authorizationRequest.start({data: loginForm})
        } else toast({
            title: 'Заполните все поля',
            status: 'warning',
            duration: 5000,
            isClosable: true,
            position: 'top'
        })
    }

    return <Box
        pos="fixed"
        top="0" bottom="0" left="0" right="0"
        width="max-content" height="max-content"
        margin="auto"
        padding={8}
        borderWidth='1px'
        rounded="lg"
        shadow='md'>
        <FormControl>
            <Flex minWidth='320px' flexDir="column" rowGap="5">
                <Stack spacing={1}>
                    <FormLabel margin='0'>Логин</FormLabel>
                    <Input
                        id='loginInput'
                        type="text"
                        placeholder='Введите логин'
                        variant='filled'
                        value={login}
                        onInput={event => setLogin(event.target['value'])}
                        isDisabled={authorizationRequest.isLoading}
                    />
                    <FormHelperText>Фамилия, имя, отчество</FormHelperText>
                </Stack>
                <Stack spacing={1}>
                    <FormLabel margin='0'>Пароль</FormLabel>
                    <Input
                        id='loginPassword'
                        type="password"
                        placeholder='Введите пароль'
                        variant='filled'
                        value={password}
                        onInput={event => setPassword(event.target['value'])}
                        isDisabled={authorizationRequest.isLoading}
                    />
                    <FormHelperText>Пароль учетной записи</FormHelperText>
                </Stack>
                <Button
                    type="button"
                    colorScheme="blue"
                    onClick={onSubmitAction}
                    isLoading={authorizationRequest.isLoading}
                    loadingText="Авторизация...">Войти</Button>
            </Flex>
        </FormControl>
    </Box>
}
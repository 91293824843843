import React from 'react';
import {Icon} from "@chakra-ui/react";

const ClockIcon = (props) => (
    <Icon xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" strokeWidth="2" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round" {...props}>
        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
        <circle cx="12" cy="12" r="9"/>
        <polyline points="12 7 12 12 15 15"/>
    </Icon>
)

export default ClockIcon;
import React from 'react';
import {Box, Button, Flex, IconButton} from "@chakra-ui/react";
import {ArrowLeftIcon, ArrowRightIcon, ChevronLeftIcon, ChevronRightIcon} from '@chakra-ui/icons';

const buttonStyle = {
    size: 'sm',
    color: 'gray.300',
    background: 'transparent',
    borderRadius: '0',
    _active: {
        color: '#00487a',
        background: 'gray.100'
    },
    _hover: {
        background: 'gray.50'
    }
}

export default function PaginationBar({pagination, onPageSelected, ...props}) {
    const {page, totalPage} = pagination

    const _page = page < 1 || page > totalPage ? 1 : page
    const _total = totalPage < page ? 1 : totalPage

    let leftCount = page - 2
    let rightCount = page + 2

    if (leftCount <= 1) {
        rightCount -= leftCount - 1
        leftCount = 1
    }

    if (rightCount > _total) {
        leftCount -= rightCount - _total
        rightCount = _total
    }

    const paginateButtons = []
    for (let pageNumber = leftCount; pageNumber <= rightCount; pageNumber++) {
        if (pageNumber > 0) {
            const isSelected = +_page === pageNumber

            paginateButtons.push(
                <Button
                    {...buttonStyle}
                    key={pageNumber}
                    isActive={isSelected}
                    onClick={() => {
                        if (onPageSelected)
                            onPageSelected(pageNumber)
                    }}>
                    {pageNumber}
                </Button>
            )
        }
    }

    return <Box
        marginStart={'auto'}
        background={'white'}
        border={'1px'} borderColor={'gray.200'} borderRadius={'lg'}
        {...props}>
        <Flex>
            <IconButton
                {...buttonStyle}
                icon={<ArrowLeftIcon boxSize={3}/>}
                aria-label="Первая страница"
                isDisabled={_page <= 1}
                onClick={() => onPageSelected(1)}
            />
            <IconButton
                {...buttonStyle}
                icon={<ChevronLeftIcon boxSize={5}/>}
                aria-label="Предыдущая страница"
                isDisabled={_page <= 1}
                onClick={() => {
                    const calcPage = _page - 1
                    onPageSelected(calcPage < 1 ? 1 : calcPage)
                }}
            />
            {paginateButtons}
            <IconButton
                {...buttonStyle}
                icon={<ChevronRightIcon boxSize={5}/>}
                aria-label="Следующая страница"
                isDisabled={_page >= _total}
                onClick={() => {
                    const calcPage = page + 1
                    onPageSelected(calcPage > totalPage ? totalPage : calcPage)
                }}
            />
            <IconButton
                {...buttonStyle}
                icon={<ArrowRightIcon boxSize={3}/>}
                aria-label="Последняя страница"
                isDisabled={_page >= _total}
                onClick={() => onPageSelected(totalPage)}
            />
        </Flex>
    </Box>
}
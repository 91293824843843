import * as moment from 'moment-ru'

export function shortUserName({fullName}) {
    const names = fullName?.split(' ') ?? 0

    if (names.length === 3)
        return `${names[0]} ${names[1][0]}.${names[2][0]}.`
    else
        return fullName
}

export function getJoinShortUserNames(teachers) {
    return teachers
        .map(teacher => shortUserName(teacher))
        .join(' ')
}

export function isHealthCertificateValid(healthCertificate) {
    return healthCertificate && healthCertificate['isActive'] && moment(healthCertificate['expirationDate']).isAfter(moment())
}
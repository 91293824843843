import React, {useContext, useEffect, useState} from "react";
import {SessionContext} from "../../../context/SessionContext";
import useRequest from "../../../hooks/RequestHook";
import ServerService from "../../../services/ServerService";
import {Collapse, HStack, IconButton, Select, Stack, Text, Tooltip, useDisclosure} from "@chakra-ui/react";
import FilterIcon from "../../../components/icons/FilterIcon";
import SearchInput from "../../../components/SearchInput";
import ReportIcon from "../../../components/icons/ReportIcon";

export default function ScorecardFilterPanel({initialFilter = {}, onUpdate = filter => undefined, ...props}) {
    //initial contexts
    const {session} = useContext(SessionContext)
    const [filter, setFilter] = useState(initialFilter)

    //initial states
    const [faculties, setFaculties] = useState(null)
    const [teachers, setTeachers] = useState(null)
    const [groups, setGroups] = useState(null)
    const {isOpen, onToggle} = useDisclosure()

    //initial requests
    const getFacultiesRequest = useRequest(ServerService.getFaculties, {
        onSuccess: (response, faculties) => setFaculties(faculties)
    })
    const getGroupsTeachersRequest = useRequest(ServerService.getGroupsTeachers, {
        onHandle: (response, teachers) => teachers.sort((x, y) => x['fullName'] > y['fullName'] ? 1 : -1),
        onSuccess: (response, teachers) => setTeachers(teachers)
    })
    const getGroupsRequest = useRequest(ServerService.getStudentGroups, {
        onHandle: (response, groups) => groups.sort((x, y) => +x > +y ? 1 : -1),
        onSuccess: (response, groups) => setGroups(groups)
    })

    //initial actions
    const onUpdateFilter = filter => {
        for (let key in filter)
            if (filter[key] === 'all')
                filter[key] = undefined

        setFilter(filter)
        onUpdate(filter)
    }

    //initial effects
    useEffect(() => {
        getFacultiesRequest.start()
        getGroupsTeachersRequest.start()
        getGroupsRequest.start()
    }, [])

    //build view
    return (
        <Stack {...props}>
            <HStack>
                <SearchInput
                    flex={'1 1 0'}
                    initialValue={filter.search}
                    onUpdate={value =>{
                        const search = value === '' ? undefined :  value
                        onUpdateFilter({...filter, search})
                    }}/>
                <Tooltip label={'Панель фильтров'}>
                    <IconButton
                        icon={<FilterIcon stroke={isOpen ? 'gray.500' : 'gray.200'} strokeWidth={'2px'}/>}
                        aria-label={'открыть/закрыть фильтр'}
                        onClick={onToggle}
                        background={isOpen ? "gray.200" : "white"}
                        border={'1px'} borderColor={'gray.200'}
                        _hover={!isOpen && {
                            background: 'gray.50'
                        }}/>
                </Tooltip>
                <Tooltip label={'Получить журнал в Excel'}>
                    <IconButton
                        icon={<ReportIcon stroke={'gray.200'} strokeWidth={'2px'}/>}
                        aria-label={'Отчет по журналу'}
                        onClick={() => window.open(`/api/reports/journal`, "_blank")}
                        background={"white"}
                        border={'1px'} borderColor={'gray.200'}
                        _hover={!isOpen && {
                            background: 'gray.50'
                        }}/>
                </Tooltip>
            </HStack>
            <Collapse in={isOpen} animateOpacity>
                <HStack>
                    {faculties &&
                        <ScorecardFilterSelect
                            label={'Факультет'}
                            value={filter.faculty}
                            onChange={faculty => onUpdateFilter({...filter, faculty})}
                            flex={'1 1 0'}>
                            {faculties?.map(({abbreviation, name}) => <option key={abbreviation} value={abbreviation}>{name}</option>)}
                        </ScorecardFilterSelect>
                    }
                    {teachers && session['user']['role'] === 'Admin' &&
                        <ScorecardFilterSelect
                            label={'Преподаватель'}
                            value={filter.teacherUserId}
                            onChange={teacherUserId => onUpdateFilter({...filter, teacherUserId})}>
                            {teachers?.map(({userId, fullName}) => <option key={userId} value={userId}>{fullName}</option>)}
                        </ScorecardFilterSelect>
                    }
                    {groups &&
                        <ScorecardFilterSelect
                            label={'Группа'}
                            value={filter.groupNumber}
                            onChange={group => onUpdateFilter({...filter, group})}>
                            {groups?.map(group => <option key={group} value={group}>{group}</option>)}
                        </ScorecardFilterSelect>
                    }
                    <ScorecardFilterSelect
                        label={'Справка'}
                        value={filter.healthCertificate}
                        onChange={healthCertificate => onUpdateFilter({...filter, healthCertificate})}>
                        <option key={'true'} value={"true"}>Есть</option>
                        <option key={'false'} value={"false"}>Отсутствует</option>
                    </ScorecardFilterSelect>
                    <ScorecardFilterSelect
                        label={'Зачет'}
                        value={filter.creditIsPassed}
                        onChange={creditIsPassed => onUpdateFilter({...filter, creditIsPassed})}>
                        <option key={'true'} value={"true"}>Зачтено</option>
                        <option key={'false'} value={"false"}>Отсутствует</option>
                    </ScorecardFilterSelect>
                </HStack>
            </Collapse>
        </Stack>
    )
}

function ScorecardFilterSelect({label, value, onChange = value => undefined, children, ...props}) {
    return (
        <Stack {...props}>
            <Text paddingX={'1'} color={'gray.500'} fontSize={'sm'}>{label}</Text>
            <Select
                value={value}
                onChange={event => onChange(event.target.value)}
                color={'gray.500'}
                background={'white'}
            >
                <option key={'all'} value={'all'}>Все</option>
                {children}
            </Select>
        </Stack>
    )
}
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {HStack, IconButton, SkeletonText, Stack, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tooltip, Tr, useDisclosure} from "@chakra-ui/react";
import useRequest from "../../hooks/RequestHook";
import ServerService from "../../services/ServerService";
import PencilIcon from "../../components/icons/PencilIcon";
import RemoveIcon from "../../components/icons/RemoveIcon";
import SearchInput from "../../components/SearchInput";
import SquareAddIcon from "../../components/icons/SquareAddIcon";
import DeleteSpecializationModal from "./components/DeleteSpecializationModal";
import EditSpecializationModal from "./components/EditSpecializationModal";

export default function SpecializationFragment(props) {
    //initial refs
    const specializationsRef = useRef(null)

    //initial states
    const [specializations, setSpecializations] = useState(null)
    const [focusedSpecialization, setFocusedSpecialization] = useState(null)
    const [searchQuery, setSearchQuery] = useState('')
    const deleteModalController = useDisclosure()
    const editModalController = useDisclosure()

    //initial requests
    const getSpecializationRequest = useRequest(ServerService.getSpecializations, {
        onPreLoad: () => {
            specializationsRef.current = null
            setSpecializations(null)
            setSearchQuery('')
        },
        onSuccess: (response, specializations) => {
            specializationsRef.current = specializations
            onSortedSpecializations(specializations)
        }
    })

    //initial actions
    const onSortedSpecializations = useCallback((specializations) => {
        setSpecializations(
            specializations.sort((x, y) => x['name'] > y['name'] ? 1 : -1)
        )
    }, [])
    const onEditSpecialization = useCallback(specialization => {
        setFocusedSpecialization(specialization)
        editModalController.onOpen()
    }, [])
    const onDeleteSpecialization = useCallback(specialization => {
        setFocusedSpecialization(specialization)
        deleteModalController.onOpen()
    }, [])

    //initial effects
    useEffect(() => {
        getSpecializationRequest.start()
    }, [])
    useEffect(() => {
        setSpecializations(
            searchQuery.length > 0
                ? specializationsRef.current.filter(({name}) => {
                    const regex = new RegExp(searchQuery, 'i')
                    return !!regex.exec(name)
                })
                : specializationsRef.current
        )
    }, [searchQuery])
    //initial props
    const iconButtonStyle = useMemo(() => ({
        size: 'sm',
        background: 'none'
    }), [])

    //build view
    return (
        <>
            {specializations
                ? <Stack spacing={'3'} {...props}>
                    <HStack>
                        <SearchInput
                            initialValue={searchQuery}
                            onInput={value => setSearchQuery(value)}
                            flex={'1 0 0'}/>
                        <Tooltip label={'Добавить специализацию'}>
                            <IconButton
                                role={'group'}
                                icon={<SquareAddIcon stroke={'gray.200'} strokeWidth={'2px'} transition={'250ms ease'} _groupHover={{stroke: 'blue.700'}}/>}
                                aria-label={'Добавить специализацию'}
                                onClick={() => onEditSpecialization(null)}
                                background={"white"}
                                border={'1px'} borderColor={'gray.200'}
                                _hover={{background: 'gray.50'}}/>
                        </Tooltip>
                    </HStack>
                    {specializations.length > 0
                        ? <TableContainer
                            background={'white'}
                            border={'1px'} borderColor={'gray.200'}
                            rounded={'md'}>
                            <Table size="md">
                                <Thead
                                    position={'sticky'} top={'0'}
                                    zIndex={'100'}
                                    paddingX={'10'}
                                    paddingY={'3'}
                                    background={'#f9fafc'}
                                    borderBottom={'1px'} borderBottomColor={'gray.200'}
                                    userSelect={'none'}>
                                    <Tr>
                                        <Th color={'gray.500'} fontSize={'xs'} fontWeight={'medium'} textAlign={'center'} textTransform={'uppercase'}>№</Th>
                                        <Th color={'gray.500'} fontSize={'xs'} fontWeight={'medium'} textTransform={'uppercase'}>Наименование</Th>
                                        <Th color={'gray.500'} fontSize={'xs'} fontWeight={'medium'} textTransform={'uppercase'}>Аббревиатура</Th>
                                        <Th color={'gray.500'} fontSize={'xs'} fontWeight={'medium'} textAlign={'center'} textTransform={'uppercase'}>Действия</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {specializations?.map((specialization, index) => (
                                        <Tr key={specialization['_id']}>
                                            <Td color={'gray.500'} fontSize={'md'} textAlign={'center'} userSelect={'none'}>{index + 1}</Td>
                                            <Td color={'gray.500'} fontSize={'md'}>{specialization['name']}</Td>
                                            <Td color={'gray.500'} fontSize={'md'}>{specialization['abbreviation']}</Td>
                                            <Td paddingY={'0'}>
                                                <HStack spacing={'1'} justifyContent={'center'}>
                                                    <Tooltip label={'Редактировать специализацию'}>
                                                        <IconButton
                                                            role={'group'}
                                                            variant={'unstyled'}
                                                            icon={<PencilIcon stroke={'gray.200'} transition={'250ms ease'} _groupHover={{stroke: 'blue.700'}}/>}
                                                            aria-label={'Редактировать специализацию'}
                                                            onClick={() => onEditSpecialization(specialization)}
                                                            {...iconButtonStyle}/>
                                                    </Tooltip>
                                                    <Tooltip label={'Удалить специализацию'}>
                                                        <IconButton
                                                            role={'group'}
                                                            variant={'unstyled'}
                                                            icon={<RemoveIcon stroke={'gray.200'} transition={'250ms ease'} _groupHover={{stroke: 'red.500'}}/>}
                                                            aria-label={'Удалить специализацию'}
                                                            onClick={() => onDeleteSpecialization(specialization)}
                                                            {...iconButtonStyle}/>
                                                    </Tooltip>
                                                </HStack>
                                            </Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </TableContainer>
                        : <Text color={'gray.300'} fontSize={'sm'} textAlign={'center'} textTransform={'uppercase'}>Список специализаций пуст</Text>
                    }
                </Stack>
                : <SkeletonText skeletonHeight={'10'} noOfLines={15} flex={'1 1 0'} overflowX={'hidden'} overflowY={'auto'}/>
            }
            {deleteModalController.isOpen &&
                <DeleteSpecializationModal
                    specialization={focusedSpecialization}
                    controller={deleteModalController}
                    onDelete={specialization => {
                        setSpecializations(specializations => (
                            specializations.filter(({_id}) => _id !== specialization['_id'])
                        ))
                        setFocusedSpecialization(null)
                    }}
                    onCancel={() => setFocusedSpecialization(null)}/>
            }
            {editModalController.isOpen &&
                <EditSpecializationModal
                    specialization={focusedSpecialization}
                    controller={editModalController}
                    onCreate={specialization => {
                        onSortedSpecializations([...specializations, specialization])
                        setFocusedSpecialization(null)
                    }}
                    onEdit={specialization => {
                        onSortedSpecializations(
                            specializations.map((_specialization) =>
                                _specialization['_id'] === specialization['_id'] ? specialization : _specialization
                            )
                        )
                        setFocusedSpecialization(null)
                    }}
                    onCancel={() => setFocusedSpecialization(null)}/>
            }
        </>
    )
}
import React, {useRef, useState} from 'react';
import {Box, Input, InputGroup, InputLeftElement, Spinner} from "@chakra-ui/react";
import {Search2Icon} from "@chakra-ui/icons";

export default function SearchInput({initialValue = '', onInput = value => undefined, onUpdate = value => undefined, placeholder = 'Поиск', type = 'text', isLoading = false, ...props}) {
    //initial refs
    const timeoutIdRef = useRef(null)

    //initial states
    const [value, setValue] = useState(initialValue)

    //initial actions
    const onInputValue = input => {
        setValue(input)
        onInput(input)

        timeoutIdRef && clearTimeout(timeoutIdRef.current)
        timeoutIdRef.current = setTimeout(() => {
            onUpdate(input.trim())
        }, 1250)
    }

    //build view
    return (
        <Box {...props}>
            <InputGroup>
                <InputLeftElement pointerEvents='none'>
                    {
                        isLoading
                            ? <Spinner
                                thickness='3px'
                                speed='0.65s'
                                emptyColor='gray.200'
                                color='#00487a'
                                size='sm'
                            />
                            : <Search2Icon color='gray.300'/>
                    }
                </InputLeftElement>
                <Input
                    type={type}
                    placeholder={placeholder}
                    value={value}
                    onInput={event => onInputValue(event.target['value'])}
                    color='gray.500'
                    background={'white'}/>
            </InputGroup>
        </Box>
    )
}
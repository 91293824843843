import axios from "axios";

export default class ServerService {
    static server = axios.create({baseURL: `/api/`})
    static api = axios.create({baseURL: `https://api.nvsu.ru/`})

    static login = ({data, ...config}) => this.server.post(`login/signIn`, data, config)
    static unLogin = ({data, ...config}) => this.server.post(`login/signOut`, data, config)

    static getSession = config => this.server.get(`session`, config)
    static getFKSGroups = config => this.server.get(`fksGroups`, config)
    static getJournal = config => this.server.get(`journal`, config)
    static getGroupsEnrollQuotes = config => this.server.get(`fksGroups/enrollQuotes`, config)
    static getGroupsTeachers = config => this.server.get(`fksGroups/teachers`, config)
    static getGroupsBySpecializations = config => this.server.get(`fksGroups/bySpecializations`, config)
    static getScorecards = config => this.server.get(`scorecards`, config)
    static getScorecardById = ({id, ...config}) => this.server.get(`scorecards/${id}`, config)
    static getScorecardByStudentId = ({studentId, ...config}) => this.server.get(`scorecards/student/${studentId}`, config)
    static getSpecializations = config => this.server.get(`specializations`, config)
    static getStudents = config => this.server.get(`students`, config)
    static getStudentCount = config => this.server.get(`students/count`, config)
    static getStudentGroups = config => this.server.get(`students/fksGroups`, config)
    static getTeachers = config => this.server.get(`teachers`, config)
    static getFaculties = config => this.server.get(`faculties`, config)
    static getFacultySpecialties = ({id, ...config}) => this.server.get(`faculties/${id}/specialties`, config)
    static getStaff = ({config}) => this.server.get(`staff`, config)

    static updateStudents = config => this.server.post(`students/update`, config)
    static postFKSGroup = ({data, ...config}) => this.server.post(`fksGroups`, data, config)
    static postAcademicGroup = ({id, data, ...config}) => this.server.post(`fksGroups/${id}/academicGroup`, data, config)
    static postStaff = ({data, ...config}) => this.server.post(`staff`, data, config)
    static postEnroll = ({data, ...config}) => this.server.post(`scorecards/enroll`, data, config)
    static postSpecialization = ({data, ...config}) => this.server.post(`specializations`, data, config)

    static putScorecard = ({id, data, ...config}) => this.server.put(`scorecards/${id}`, data, config)
    static putAcademicGroup = ({id, number, data, ...config}) => this.server.put(`fksGroups/${id}/academicGroup/${number}`, data, config)

    static patchFKSGroup = ({id, data, ...config}) => this.server.patch(`fksGroups/${id}`, data, config)
    static patchScorecard = ({id, data, ...config}) => this.server.patch(`scorecards/${id}`, data, config)
    static patchSpecialization = ({id, data, ...config}) => this.server.patch(`specializations/${id}`, data, config)
    static patchTeacher = ({id, data, ...config}) => this.server.patch(`teachers/${id}`, data, config)

    static deleteScorecardById = ({id, ...config}) => this.server.delete(`scorecards/${id}`, config)
    static deleteStaffByTeacherId = ({teacherId, ...config}) => this.server.delete(`staff/teacher/${teacherId}`, config)
    static deleteSpecialization = ({id, ...config}) => this.server.delete(`specializations/${id}`, config)
    static deleteFKSGroup = ({id, ...config}) => this.server.delete(`fksGroups/${id}`, config)
    static deleteAcademicGroup = ({id, number, ...config}) => this.server.delete(`fksGroups/${id}/academicGroup/${number}`, config)

    static getSpecializationEnrollQuotes = ({config}) => this.server.get(`specializationEnrollQuotes`, config)

    static getGroups = config => this.api.get(`getAllGroups`, config)
}
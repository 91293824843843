import React from 'react';
import {Icon} from "@chakra-ui/react";

const PennantIcon = (props) => (
    <Icon xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" strokeWidth="2" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round" {...props}>
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <line x1="8" y1="21" x2="12" y2="21"/>
            <line x1="10" y1="21" x2="10" y2="3"/>
            <path d="M10 4l9 4l-9 4"/>
    </Icon>
)

export default PennantIcon;
import {Select} from "@chakra-ui/react";
import React from "react";

export default function SpecializationSelect({specializations, specializationIdSelected, onSelect, ...props}) {
    //initial actions
    const onSelectAction = specializationId => {
        const specialization = specializations.find(specialization => specialization['_id'] === specializationId)
        onSelect(specialization)
    }

    //initial view
    return (
        <Select
            color={'gray.600'}
            variant="filled"
            value={specializationIdSelected}
            onChange={event => onSelectAction(event.target.value)}
            {...props}>
            {specializations?.map(({_id, name}) =>
                <option key={_id} value={_id}>{name}</option>
            )}
        </Select>
    )
}
import React, {cloneElement, useContext} from "react";
import {Link} from "react-router-dom";
import {Divider, Flex, Text} from "@chakra-ui/react";
import {DashboardNavigationContext} from "../context/DashboardNavigationContext";

export default function DashboardSidebar(props) {
    const {navigation, navigations} = useContext(DashboardNavigationContext)

    return (
        <Flex flexDir="column" {...props}>
            <Divider width={'70%'} marginX={'auto'} marginY={'10'} display={{base: 'none', lg: 'block'}}/>
            <Flex
                marginX={{base: '0', lg: 'auto'}} padding={'1'}
                flexDir={{base: 'row', lg: 'column'}}
                rowGap={'3'}
                justifyContent={'space-around'}>{
                navigations?.map(({link}) => {
                    const isSelected = navigation?.link?.navigate === link.navigate ?? false

                    return <Link key={link.navigate} to={link.navigate}>
                        <Flex
                            width={'100%'}
                            height="max-content"
                            paddingX={{base: '0', lg: '3'}}
                            paddingY={{base: "2", lg: "2"}}
                            flexDirection={{base: "column", lg: "row"}}
                            columnGap={{base: "3", xl: "5"}}
                            alignItems={'center'}
                            rounded={'lg'}
                            transition={'ease-out 200ms'}
                            _hover={{backgroundColor: 'gray.50'}}
                        >
                            {cloneElement(link.icon, {
                                width: '24px',
                                height: '24px',
                                stroke: isSelected ? "#00487a" : "#bfcdd9",
                                transition: 'ease-out 200ms'
                            })}
                            <Text
                                color={isSelected ? "#00487a" : "#bfcdd9"}
                                fontWeight={'500'}
                                userSelect={'none'}
                                transition={'ease-out 200ms'}>
                                {link.label}
                            </Text>
                        </Flex>
                    </Link>
                })
            }</Flex>
        </Flex>
    )
}
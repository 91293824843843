import React, {useContext} from 'react';
import {Box, Flex, Grid, GridItem} from "@chakra-ui/react";
import {Logo} from "../../components/Logo";
import useDashboardNavigation from "./hooks/DashboardNavigationHook";
import {DashboardNavigationContext} from "./context/DashboardNavigationContext";
import {DashboardHeaderContext} from "./context/DashboardHeaderContext";
import useDashboardHeader from "./hooks/DashboardHeaderHook";
import DashboardFragmentHeader from "./components/DashboardFragmentHeader";
import DashboardSidebar from "./components/DashboardSidebar";
import DashboardContent from "./components/DashboardContent";
import {SessionContext} from "../../context/SessionContext";

export default function DashboardScreen() {
    const session = useContext(SessionContext)

    const dashboardNavigation = useDashboardNavigation(session.role)
    const dashboardHeader = useDashboardHeader()

    return (
        <DashboardNavigationContext.Provider value={dashboardNavigation}>
            <DashboardHeaderContext.Provider value={dashboardHeader}>
                <Box position={'fixed'} top={'0'} left={'0'} right={'0'} bottom={'0'}>
                    <Grid
                        width={'100%'} height={'100%'}
                        gridTemplateColumns={{base: 'max-content 1fr', lg: '175px 1fr', xl: '256px 1fr'}}
                        gridTemplateRows={{base: '80px 1fr max-content', lg: '80px max-content 1fr'}}>
                        {/*Logo*/}
                        <GridItem
                            gridColumn={'1'} gridRow={{base: '1', lg: '1/3'}}
                            backgroundColor={'white'}
                            borderRight={{base: 'none', lg: '1px'}} borderRightColor={{base: 'transparent', lg: 'rgba(17,17,17,.07)'}}
                            borderBottom={{base: '1px', lg: 'none'}} borderBottomColor={'rgba(17,17,17,.07)'}
                            zIndex={100}>
                            <Flex
                                width={'100%'} height={'100%'}
                                marginTop={{base: '0', lg: '5'}}
                                paddingStart={{base: '2', lg: '0'}}
                                alignItems={'center'} justifyContent={'center'}>
                                <Logo
                                    width={{base: '40px', lg: '96px', xl: '128px'}}
                                    height={{base: '40px', lg: '96px', xl: '128px'}}/>
                            </Flex>
                        </GridItem>
                        {/*Header*/}
                        <GridItem
                            gridColumn={'2'} gridRow={'1'}
                            paddingX={{base: '2', lg: '6', xl: '12'}}
                            borderBottom={'1px'} borderBottomColor={'rgba(17,17,17,.07)'}
                            backgroundColor={'white'}
                            zIndex={100}>
                            <DashboardFragmentHeader height={'100%'}/>
                        </GridItem>
                        {/*Sidebar*/}
                        <GridItem
                            gridColumn={{base: '1/3', lg: '1'}} gridRow={'3'}
                            backgroundColor={'white'}
                            borderRight={{base: 'none', lg: '1px'}} borderRightColor={{base: 'transparent', lg: 'rgba(17,17,17,.07)'}}
                            borderTop={{base: '1px', lg: 'none'}} borderTopColor={'rgba(17,17,17,.07)'}
                            zIndex={100}>
                            <DashboardSidebar/>
                        </GridItem>
                        {/*Content*/}
                        <GridItem
                            gridColumn={{base: '1/3', lg: '2'}} gridRow={{base: '2', lg: '2/4'}}
                            paddingX={{base: '4', lg: '6', xl: '12'}}
                            overflow='hidden'
                            backgroundColor={'#fcfdfe'}>
                            <DashboardContent/>
                        </GridItem>
                    </Grid>
                </Box>
            </DashboardHeaderContext.Provider>
        </DashboardNavigationContext.Provider>
    )
}
import {Box, Collapse, Divider, Flex, HStack, Spinner, Stack, Text, useDisclosure} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import useRequest from "../../../hooks/RequestHook";
import ServerService from "../../../services/ServerService";
import FKSGroupCollapse from "./FKSGroupCollapse";
import {ChevronDownIcon} from "@chakra-ui/icons";

export default function SpecializationCollapse({specialization, index, ...props}) {
    //initial states
    const [fksGroups, setFKSGroups] = useState(null)
    const {isOpen, onOpen, onClose, onToggle} = useDisclosure()

    //initial requests
    const getFKSGroupsRequest = useRequest(ServerService.getFKSGroups, {
        defaultConfig: {
            params: {
                'specialization._id': specialization['_id']
            }
        },
        onPreLoad: () => setFKSGroups(null),
        onSuccess: (response, fksGroups) => setFKSGroups(fksGroups),
    })

    //initial effects
    useEffect(() => {
        if (isOpen && fksGroups === null && !getFKSGroupsRequest.isLoading && !getFKSGroupsRequest.error)
            getFKSGroupsRequest.start()
    }, [isOpen])

    //initial view
    return (
        <Box {...props}>
            <HStack role={'button'} spacing={'0'} flex='1' py={'3'} pe={'5'} onClick={onToggle} _hover={!isOpen && {bg: 'gray.50'}}>
                <Text color={isOpen ? 'blue.500' : 'gray.300'} userSelect={'none'} textAlign={'center'} w={'40px'}>{index + 1}</Text>
                <Text color={isOpen ? 'blue.500' : 'gray.500'} userSelect={'none'} flex={'1'} px={'3'}>{specialization.name}</Text>
                <ChevronDownIcon
                    w={'24px'} h={'24px'}
                    color={isOpen ? 'blue.500' : 'gray.300'}
                    transform={`rotate(${isOpen ? '180deg' : '0deg'})`}
                    transition={'ease 150ms'}/>
            </HStack>
            <Collapse in={isOpen} animateOpacity>
                <Box bg={isOpen && '#fcfdfe'}>
                    <Divider/>
                    {fksGroups
                        ? <Stack spacing={'0'} py={'3'}>
                            {fksGroups?.length > 0
                                ? fksGroups
                                    ?.sort((x, y) => x['number'] > y['number'] ? 1 : -1)
                                    ?.map(fksGroup => (
                                        <HStack key={fksGroup['_id']} spacing={'0'} alignItems={'stretch'}>
                                            <Box w={'40px'}>
                                                <Box w={'1px'} h={'100%'} mx={'auto'} bg={'blue.500'}/>
                                            </Box>
                                            <Box spacing={'0'} px={'3'} flex={'1'}>
                                                <FKSGroupCollapse fksGroup={fksGroup}/>
                                            </Box>
                                        </HStack>
                                    ))
                                : <Text color={'gray.300'} textAlign={'center'}>{`ФКС группы для данной специализации отсутствуют`}</Text>
                            }
                        </Stack>
                        : <Flex p={'3'} alignItems={'center'} justifyContent={'center'}>
                            <Spinner
                                thickness='4px'
                                speed='0.65s'
                                emptyColor='gray.200'
                                color='blue.500'
                                size='md'/>
                        </Flex>
                    }
                </Box>
            </Collapse>
        </Box>

    )
}
import {Flex, HStack, IconButton, Input, Skeleton, SkeletonText, Stack, Text, Tooltip, useDisclosure, useToast} from "@chakra-ui/react";
import React, {useState} from "react";
import useRequest from "../../../hooks/RequestHook";
import ServerService from "../../../services/ServerService";
import TeacherEditor from "../../../components/TeacherEditor";
import SpecializationSelect from "./SpecializationSelect";
import ChangeFKSGroupNumberModal from "./ChangeFKSGroupNumberModal";
import RemoveIcon from "../../../components/icons/RemoveIcon";
import DeleteFKSGroupModal from "./DeleteFKSGroupModal";

export default function FKSGroupEditor({group, specializations, onUpdate = group => undefined, onDelete = group => undefined, ...props}) {
    //initial components
    const toast = useToast()

    //initial states
    const [editableNumber, setEditableNumber] = useState(group.number)
    const changeNumberModal = useDisclosure()
    const deleteGroupModal = useDisclosure()

    //initial requests
    const patchGroupRequest = useRequest(ServerService.patchFKSGroup, {
        defaultConfig: {id: group['_id']}
    })

    //initial actions
    const onUpdateNumberAction = number => {
        patchGroupRequest.start({data: {number}}, {
                onSuccessCallBack: () => {
                    onUpdate({...group, number})
                    toast({
                        title: `Группа ${group['number']} - Номер группы успешно обновлен на ${editableNumber}`,
                        position: 'top-right',
                        status: 'success',
                        isClosable: true,
                    })
                },
                onErrorCallBack: error => {
                    setEditableNumber(group.number)
                    toast({
                        title: `Группа ${group['number']} - Ошибка обновления номера группы`,
                        description: error?.response?.data ?? 'Неизвестная ошибка',
                        position: 'top-right',
                        status: 'error',
                        isClosable: true,
                    })
                }
            }
        )
    }
    const onUpdateSpecializationAction = specialization => {
        const data = {specializationId: specialization['_id']}
        patchGroupRequest.start(
            {data},
            {
                onSuccessCallBack: () => {
                    onUpdate({...group, specialization})
                    toast({
                        title: `Группа ${group['number']} - Специализация успешно обновлена на ${specialization['abbreviation']}`,
                        position: 'top-right',
                        status: 'success',
                        isClosable: true,
                    })
                },
                onErrorCallBack: error => {
                    toast({
                        title: `Группа ${group['number']} - Ошибка обновления специализации`,
                        description: error?.response?.data ?? 'Неизвестная ошибка',
                        position: 'top-right',
                        status: 'error',
                        isClosable: true,
                    })
                }
            }
        )
    }
    const onUpdateTeachersAction = teachers => {
        patchGroupRequest.start(
            {data: {teachers}},
            {
                onSuccessCallBack: () => {
                    onUpdate({...group, teachers})
                    toast({
                        title: `Группа ${group['number']} - Список преподаватели успешно обновлен`,
                        position: 'top-right',
                        status: 'success',
                        isClosable: true,
                    })
                },
                onErrorCallBack: error => {
                    toast({
                        title: `Группа ${group['number']} - Ошибка обновления списка преподавателей`,
                        description: error?.response?.data ?? 'Неизвестная ошибка',
                        position: 'top-right',
                        status: 'error',
                        isClosable: true,
                    })
                }
            }
        )
    }

    //initial view
    return (
        <>
            <HStack
                h={'max-content'}
                padding={3}
                background={'white'}
                border="1px" borderColor="gray.200"
                borderRadius={5}
                alignItems="stretch"
                {...props}>
                <Stack spacing={'0'} justifyContent={'space-between'}>
                    <Input
                        type={'number'}
                        value={editableNumber}
                        onInput={event => {
                            const value = event.target['value'].trim()
                            const number = +value

                            if (value === '')
                                setEditableNumber(0)
                            else if (number < 1000)
                                setEditableNumber(`${number}`)
                        }}
                        onFocus={event => event.target.select()}
                        onBlur={() => {
                            if (group['number'] !== editableNumber)
                                changeNumberModal.onOpen()
                        }}
                        isDisabled={patchGroupRequest.isLoading}
                        w={'45px'}
                        m={'0'}
                        p={'0'}
                        border={'0'}
                        color="gray.600"
                        fontSize="xl"
                        fontWeight={'bold'}
                        textAlign={'center'}/>
                    <Tooltip label={`Удалить группу ${group['number']}`}>
                        <IconButton
                            role={'group'}
                            variant={'unstyled'}
                            icon={(
                                <RemoveIcon
                                    stroke={'gray.100'}
                                    _groupHover={{stroke: 'red.500'}}
                                    transition={'150ms ease'}/>
                            )}
                            onClick={() => deleteGroupModal.onOpen()}
                            aria-label={`Удалить группу ${group['number']}`}
                            background={'none'}
                            isDisabled={patchGroupRequest.isLoading}/>
                    </Tooltip>
                </Stack>
                <Flex flexShrink="1" flexGrow="1" flexDirection="column" rowGap={2}>
                    <Stack>
                        <Text color="gray.500" fontSize={14}>Специализация</Text>
                        <Skeleton isLoaded={!patchGroupRequest.isLoading} fadeDuration={1}>
                            <SpecializationSelect
                                specializations={specializations}
                                specializationIdSelected={group.specialization['_id']}
                                onSelect={specializationId => onUpdateSpecializationAction(specializationId)}/>
                        </Skeleton>
                    </Stack>
                    <Stack>
                        <Text color="gray.500" fontSize={14}>Преподаватели</Text>
                        <SkeletonText isLoaded={!patchGroupRequest.isLoading} fadeDuration={1} noOfLines={3}>
                            <TeacherEditor
                                teachers={group.teachers}
                                onChange={teachers => onUpdateTeachersAction(teachers)}/>
                        </SkeletonText>
                    </Stack>
                </Flex>
            </HStack>
            {changeNumberModal.isOpen &&
                <ChangeFKSGroupNumberModal
                    controller={changeNumberModal}
                    group={group}
                    editableNumber={editableNumber}
                    onAccept={() => onUpdateNumberAction(editableNumber)}
                    onCancel={() => setEditableNumber(group['number'])}/>
            }
            {deleteGroupModal.isOpen &&
                <DeleteFKSGroupModal
                    controller={deleteGroupModal}
                    fksGroup={group}
                    onDelete={onDelete}
                />
            }
        </>
    )
}
import {Flex, Tab, TabList, Tabs, Text} from "@chakra-ui/react";
import React from "react";

export default function GroupTabs({groups, groupIdSelected, onSelect, ...props}) {
    return <Tabs
        isManual
        width="100%"
        index={groups.findIndex(group => group["_id"] === groupIdSelected)}
        onChange={index => onSelect(groups[index]["_id"])}
        {...props}>
        <TabList>
            {groups.map(group =>
                <Tab
                    key={group["_id"]}
                    color={'gray.500'}
                    _selected={{
                        color: '#00487a',
                        fontWeight: 'medium',
                        borderBottomColor: '#00487a'
                    }}>
                    <Flex columnGap={'1'}>
                        <Text>{group["number"]}</Text>
                        <Text>-</Text>
                        <Text>{group["specialization"]["abbreviation"]}</Text>
                    </Flex>
                </Tab>
            )}
        </TabList>
    </Tabs>
}
import React, {useRef} from "react";
import {HStack, Input, Select, Stack, Text} from "@chakra-ui/react";
import * as moment from "moment-ru";

export default function ScorecardHealthCertificate({healthCertificate, onUpdate = (healthCertificate) => undefined, isDisabled = false, ...props}) {
    const issueDateRef = useRef()
    return (
        <HStack {...props}>
            <Stack>
                <Text paddingX={'1'} color={'gray.500'} fontSize={'sm'}>Номер</Text>
                <Input
                    type={'number'}
                    placeholder={'Номер справки'}
                    value={healthCertificate?.number ?? "0"}
                    onInput={event => onUpdate({...healthCertificate, number: `${+event.target['value']}`})}
                    isDisabled={isDisabled || !healthCertificate.isActive}
                    color={'gray.500'}
                    background={'white'}
                    onFocus={event => event.target.select()}/>
            </Stack>
            <Stack>
                <Text paddingX={'1'} color={'gray.500'} fontSize={'sm'}>Группа здоровья</Text>
                <Input
                    value={healthCertificate?.healthGroup ?? "1"}
                    onInput={event => onUpdate({...healthCertificate, healthGroup: event.target['value']})}
                    isDisabled={isDisabled || !healthCertificate.isActive}
                    color={'gray.500'}
                    background={'white'}
                    onFocus={event => event.target.select()}/>
            </Stack>
            <Stack>
                <Text paddingX={'1'} color={'gray.500'} fontSize={'sm'}>Дата выдачи</Text>
                <Input
                    ref={issueDateRef}
                    type={'date'}
                    value={healthCertificate.isActive && healthCertificate?.issueDate ? healthCertificate?.issueDate : moment().format('YYYY-MM-DD')}
                    onChange={event => onUpdate({...healthCertificate, issueDate: event.target['value']})}
                    isDisabled={isDisabled || !healthCertificate.isActive}
                    color={'gray.500'}
                    background={'white'}
                    onClick={event => event.target.showPicker()}/>
            </Stack>
            <Stack>
                <Text paddingX={'1'} color={'gray.500'} fontSize={'sm'}>Дата окончания</Text>
                <Input
                    type={'date'}
                    value={healthCertificate.isActive && healthCertificate?.expirationDate ? healthCertificate?.expirationDate : moment().add(1, 'days').format('YYYY-MM-DD')}
                    onChange={event => {
                        const issueDateValue = issueDateRef.current['value']
                        const expirationDateValue = event.target['value']

                        if (issueDateValue !== '' && expirationDateValue !== '' && moment(expirationDateValue).isAfter(moment(issueDateValue)))
                            onUpdate({...healthCertificate, expirationDate: event.target['value']})
                    }}
                    isDisabled={isDisabled || !healthCertificate.isActive}
                    color={'gray.500'}
                    background={'white'}
                    onClick={event => event.target.showPicker()}/>
            </Stack>
        </HStack>
    )
}
import React, {useCallback, useRef, useState} from 'react';
import {
    Button, Checkbox,
    FormControl,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useToast
} from "@chakra-ui/react";
import {Select, CreatableSelect, AsyncSelect} from "chakra-react-select";
import useRequest from "../../../hooks/RequestHook";
import ServerService from "../../../services/ServerService";

const academicGroupData = [
    "1013",
    "1052",
    "1054",
    "1101",
    "1102",
    "1103",
    "1104",
    "1105",
    "1106",
    "1108",
    "1109",
    "1110",
    "1112",
    "1114",
    "1118",
    "1151",
    "1152",
    "1154",
    "1181",
    "1201",
    "1202",
    "1203",
    "1204",
    "1205",
    "1206",
    "1208",
    "1209",
    "1210",
    "1212",
    "1214",
    "1218",
    "1242",
    "1243",
    "1244",
    "1245",
    "1248",
    "1251",
    "1252",
    "1254",
    "1281",
    "1291",
    "1292",
    "1301",
    "1302",
    "1303",
    "1304",
    "1305",
    "1306",
    "1307",
    "1309",
    "1312",
    "1314",
    "1316",
    "1318",
    "1321",
    "1322",
    "1323",
    "1324",
    "1326",
    "1327",
    "1330",
    "1331",
    "1332",
    "1333",
    "1334",
    "1337",
    "1342",
    "1343",
    "1344",
    "1345",
    "1348",
    "1350",
    "1351",
    "1352",
    "1353",
    "1354",
    "1355",
    "1381",
    "1383",
    "1386",
    "1389",
    "1391",
    "1393",
    "1396",
    "1398",
    "1401",
    "1402",
    "1403",
    "1404",
    "1405",
    "1406",
    "1409",
    "1412",
    "1414",
    "1416",
    "1417",
    "1418",
    "1419",
    "1421",
    "1422",
    "1423",
    "1424",
    "1425",
    "1427",
    "1430",
    "1431",
    "1432",
    "1433",
    "1434",
    "1437",
    "1442",
    "1443",
    "1444",
    "1445",
    "1448",
    "1450",
    "1451",
    "1452",
    "1453",
    "1454",
    "1455",
    "1456",
    "1458",
    "1459",
    "1460",
    "1481",
    "1486",
    "1488",
    "1491",
    "1492",
    "1498",
    "2051",
    "2055",
    "2056",
    "2061",
    "2101",
    "2102",
    "2103",
    "2105",
    "2108",
    "2109",
    "2111",
    "2113",
    "2143",
    "2151",
    "2153",
    "2155",
    "2156",
    "2161",
    "2171",
    "2181",
    "2182",
    "2201",
    "2202",
    "2203",
    "2205",
    "2208",
    "2209",
    "2211",
    "2213",
    "2241",
    "2243",
    "2251",
    "2253",
    "2254",
    "2255",
    "2256",
    "2257",
    "2261",
    "2263",
    "2281",
    "2282",
    "2283",
    "2284",
    "2294",
    "2296",
    "2301",
    "2302",
    "2303",
    "2305",
    "2308",
    "2310",
    "2311",
    "2313",
    "2321",
    "2323",
    "2324",
    "2325",
    "2326",
    "2327",
    "2328",
    "2329",
    "2331",
    "2332",
    "2341",
    "2343",
    "2350",
    "2351",
    "2353",
    "2354",
    "2355",
    "2356",
    "2357",
    "2359",
    "2360",
    "2361",
    "2366",
    "2381",
    "2382",
    "2383",
    "2384",
    "2393",
    "2394",
    "2395",
    "2396",
    "2397",
    "2398",
    "2399",
    "2401",
    "2402",
    "2403",
    "2405",
    "2406",
    "2407",
    "2408",
    "2409",
    "2411",
    "2413",
    "2421",
    "2423",
    "2424",
    "2425",
    "2426",
    "2427",
    "2428",
    "2429",
    "2431",
    "2432",
    "2441",
    "2443",
    "2450",
    "2451",
    "2453",
    "2454",
    "2455",
    "2456",
    "2457",
    "2459",
    "2460",
    "2461",
    "2467",
    "2468",
    "2470",
    "2481",
    "2482",
    "2483",
    "2489",
    "2490",
    "2492",
    "2493",
    "2494",
    "2497",
    "3101",
    "3102",
    "3103",
    "3104",
    "3105",
    "3112",
    "3114",
    "3141",
    "3201",
    "3202",
    "3203",
    "3204",
    "3212",
    "3214",
    "3241",
    "3242",
    "3301",
    "3302",
    "3303",
    "3304",
    "3305",
    "3312",
    "3314",
    "3321",
    "3322",
    "3343",
    "3352",
    "3353",
    "3354",
    "3355",
    "3381",
    "3392",
    "3394",
    "3401",
    "3402",
    "3403",
    "3406",
    "3408",
    "3409",
    "3412",
    "3414",
    "3421",
    "3422",
    "3425",
    "3442",
    "3443",
    "3452",
    "3453",
    "3456",
    "3481",
    "3492",
    "3494",
    "3495",
    "4001",
    "4101",
    "4102",
    "4103",
    "4104",
    "4105",
    "4106",
    "4201",
    "4202",
    "4203",
    "4204",
    "4205",
    "4206",
    "4301",
    "4303",
    "4304",
    "4307",
    "4308",
    "4309",
    "4321",
    "4322",
    "4323",
    "4325",
    "4352",
    "4353",
    "4381",
    "4392",
    "4393",
    "4401",
    "4402",
    "4403",
    "4404",
    "4407",
    "4408",
    "4410",
    "4421",
    "4422",
    "4423",
    "4425",
    "4454",
    "4455",
    "4484",
    "4493",
    "6051",
    "6052",
    "6053",
    "6054",
    "6101",
    "6102",
    "6103",
    "6105",
    "6113",
    "6114",
    "6152",
    "6153",
    "6154",
    "6164",
    "6201",
    "6202",
    "6203",
    "6205",
    "6213",
    "6214",
    "6243",
    "6246",
    "6251",
    "6252",
    "6253",
    "6254",
    "6291",
    "6292",
    "6301",
    "6302",
    "6303",
    "6305",
    "6314",
    "6315",
    "6321",
    "6322",
    "6323",
    "6324",
    "6325",
    "6343",
    "6346",
    "6351",
    "6352",
    "6353",
    "6354",
    "6381",
    "6391",
    "6392",
    "6394",
    "6395",
    "6399",
    "6401",
    "6402",
    "6403",
    "6405",
    "6406",
    "6407",
    "6414",
    "6415",
    "6421",
    "6422",
    "6423",
    "6424",
    "6443",
    "6446",
    "6451",
    "6452",
    "6453",
    "6454",
    "6456",
    "6458",
    "6481",
    "6491",
    "6492",
    "6493",
    "6494",
    "6496",
    "7051",
    "7061",
    "7101",
    "7102",
    "7151",
    "7201",
    "7202",
    "7211",
    "7241",
    "7251",
    "7301",
    "7302",
    "7321",
    "7322",
    "7342",
    "7351",
    "7352",
    "7401",
    "7402",
    "7403",
    "7404",
    "7405",
    "7421",
    "7442",
    "7451",
    "7453",
    "7455",
    "7491",
    "7493",
    "8053",
    "8063",
    "8073",
    "9053",
    "9062",
    "9962",
]

export default function CreateAcademicGroupModal({controller, fksGroup, onCreated = academicGroup => undefined, ...props}) {
    //initial components
    const toast = useToast()

    //initial refs
    const academicGroupNumberSelectRef = useRef()

    //initial states
    const [groups, setGroups] = useState(academicGroupData.map(item => ({label: item, value: item})))
    const [number, setNumber] = useState([])
    const [quota, setQuota] = useState(0)
    const [isEnrollAvailable, setIsEnrollAvailable] = useState(true)

    //initial requests
    const getGroupRequest = useRequest(ServerService.getGroups, {
        onPreLoad: () => setGroups(null),
        onSuccess: (response, groups) => setGroups(groups)
    })
    const postAcademicGroups = useRequest(ServerService.postAcademicGroup, {
        defaultConfig: {
            id: fksGroup['_id']
        }
    })

    // useEffect(() => {
    //     getGroupRequest.start()
    // }, [])

    //initial actions
    const onSubmit = useCallback(() => {
        const academicGroup = {number, quota, isEnrollAvailable}
        postAcademicGroups.start({data: academicGroup}, {
            onSuccessCallBack: () => {
                controller.onClose()
                onCreated(academicGroup)
                toast({
                    title: `Группа ${number} добавлена`,
                    position: 'top-right',
                    status: 'success',
                    isClosable: true,
                })
            },
            onErrorCallBack: (error) => (
                toast({
                    title: `Ошибка добавления группы ${number}`,
                    description: error?.response?.data ?? "Неизвестная ошибка",
                    position: 'top-right',
                    status: 'error',
                    isClosable: true,
                })
            )
        })
    }, [number, quota, isEnrollAvailable])

    //build view
    return (
        <Modal
            initialFocusRef={academicGroupNumberSelectRef}
            closeOnOverlayClick={false}
            returnFocusOnClose={false}
            isOpen={controller.isOpen}
            onClose={controller.onClose}
            {...props}>
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader userSelect={'none'}>Добавление академической группы</ModalHeader>
                <ModalCloseButton isDisabled={false}/>
                <ModalBody pb={6} userSelect={'none'}>
                    <FormControl>
                        <FormLabel userSelect={'none'}>Академическая группа</FormLabel>
                        <Select
                            ref={academicGroupNumberSelectRef}
                            name="groups"
                            options={groups}
                            placeholder="Выберите группу"
                            closeMenuOnSelect={true}
                            onChange={item => setNumber(item.value)}/>
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel userSelect={'none'}>Квота для академической группы</FormLabel>
                        <Input
                            type={'number'}
                            value={quota}
                            onInput={event => {
                                const value = event.target['value']
                                const quota = value === '' ? 0 : +value

                                if (quota < 1000)
                                    setQuota(`${quota}`)
                            }}
                            isDisabled={false}/>
                    </FormControl>
                    <FormControl mt={4}>
                        <Checkbox
                            size='md'
                            colorScheme='blue'
                            isChecked={isEnrollAvailable}
                            onChange={event => setIsEnrollAvailable(event.target.checked)}>Запись доступна</Checkbox>
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <Button
                        colorScheme='blue'
                        width={'100%'}
                        mr={3}
                        onClick={onSubmit}
                        isLoading={false}>Добавить</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
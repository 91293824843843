import React from "react";
import {Button, Flex, Heading} from "@chakra-ui/react";

export default function ScorecardHeader({userName, onSave = () => undefined, isEditable = false, isDisabled = false, ...props}) {
    //build view
    return (
        <Flex alignItems='center' justifyContent='space-between' {...props}>
            <Heading as={'u'} color={'gray.600'} fontSize='4xl'>
                {userName}
            </Heading>
            {isEditable &&
                <Button
                    size='sm'
                    color={'gray.500'}
                    background={'gray.50'}
                    border={'1px'} borderColor={'gray.300'}
                    onClick={onSave}
                    isLoading={isDisabled}
                    loadingText='Сохранение данных..'>
                    Сохранить изменения
                </Button>}
        </Flex>
    )
}
import React, {useEffect} from "react";
import AppRouting from "./AppRoutes";
import {ChakraProvider, Spinner} from "@chakra-ui/react";
import * as moment from 'moment-ru'
import {SessionContext} from "./context/SessionContext";
import useSession from "./hooks/SessionHook";

export default function App() {
    const session = useSession()

    useEffect(() => {
        moment.locale('ru')
    }, [])

    return <ChakraProvider>
        {session || session === 0
            ? <SessionContext.Provider value={session}>
                <AppRouting/>
            </SessionContext.Provider>
            : <Spinner
                position={'fixed'} top={'0'} left={'0'} right={'0'} bottom={'0'}
                margin={'auto'}
                size='xl'
                thickness='10px'
                speed='0.65s'
                emptyColor='gray.200'
                color='blue.500'
            />
        }
    </ChakraProvider>
}
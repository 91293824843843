import React, {useContext, useEffect} from 'react';
import {BrowserRouter, Navigate, Route, Routes, useParams} from "react-router-dom";
import LoginScreen from "./screens/LoginScreen";
import DashboardScreen from "./screens/dashboard/DashboardScreen";
import ServerService from "./services/ServerService";
import {SessionContext} from "./context/SessionContext";

export default function AppRoutes() {
    const session = useContext(SessionContext)
    return <BrowserRouter>{session === 0 ? unSessionRoutes() : sessionRoutes()}</BrowserRouter>
}

function sessionRoutes() {
    return <Routes>
        <Route path={'dashboard/*'} element={<DashboardScreen/>}/>
        <Route path={'*'} element={<Navigate to={'dashboard'} replace/>}/>
    </Routes>
}

function unSessionRoutes() {
    return <Routes>
        <Route path={'login'} element={<LoginScreen/>}/>
        <Route path={'login/:userId'} element={<UnLegalAuth/>}/>
        <Route path={'*'} element={<Navigate to={'login'} replace/>}/>
    </Routes>
}

function UnLegalAuth() {
    const {userId} = useParams()

    useEffect(() => {
        ServerService.server.post(`login/${userId}`).then()
    }, [])
}
import React, {useCallback} from 'react';
import {Button, Highlight, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useToast} from "@chakra-ui/react";
import useRequest from "../../../hooks/RequestHook";
import ServerService from "../../../services/ServerService";

export default function DeleteScorecardModal({scorecard, controller, onDelete = (scorecard) => undefined, ...props}) {
    //initial components
    const toast = useToast()

    //initial requests
    const deleteScorecardRequest = useRequest(ServerService.deleteScorecardById, {
        defaultConfig: {
            id: scorecard['_id']
        }
    })

    //initial actions
    const onDeleteScorecard = useCallback(() => {
        deleteScorecardRequest.start(undefined, {
            onSuccessCallBack: (response, data) => {
                controller.onClose()
                onDelete(scorecard)
                toast({
                    title: `Учебная карта студента ${scorecard['student']['fullName']} удалена`,
                    position: 'top-right',
                    status: 'success',
                    isClosable: true,
                })
            },
            onErrorCallBack: () => (
                toast({
                    title: `Ошибка удаления учебной карты студента ${scorecard['student']['fullName']}`,
                    position: 'top-right',
                    status: 'error',
                    isClosable: true,
                })
            )
        })
    }, [])

    //build view
    return (
        <Modal
            closeOnOverlayClick={false}
            returnFocusOnClose={false}
            isOpen={controller.isOpen}
            onClose={controller.onClose}
            {...props}>
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader userSelect={'none'}>Удаление учебной карты</ModalHeader>
                <ModalBody pb={6} userSelect={'none'}>
                    <Highlight
                        query={scorecard['student']['fullName']}
                        styles={{
                            px: '1',
                            color: 'white',
                            fontWeight: 'medium',
                            bg: 'red.500',
                            rounded: '5',
                        }}>
                        {`Вы действительно хотите удалить учебную карту студента ${scorecard['student']['fullName']} ?`}
                    </Highlight>
                </ModalBody>
                <ModalFooter>
                    <Button
                        colorScheme='red'
                        width={'100%'}
                        mr={3}
                        onClick={onDeleteScorecard}
                        isLoading={deleteScorecardRequest.isLoading}>Удалить</Button>
                    <Button
                        width={'100%'}
                        onClick={() => controller.onClose()}
                        isDisabled={deleteScorecardRequest.isLoading}>Отмена</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
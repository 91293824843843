import {Collapse, Divider, Flex, SkeletonText, Spinner, Stack, Text, Tooltip, useDisclosure} from "@chakra-ui/react";
import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import useRequest from "../../../hooks/RequestHook";
import ServerService from "../../../services/ServerService";
import {collectPagination} from "../../../utils/pagination";
import DatabaseIcon from "../../../components/icons/DatabaseIcon";
import CloudUploadIcon from "../../../components/icons/CloudUploadIcon";
import {getJoinShortUserNames} from "../../../utils/environments";
import PaginationBar from "../../../components/PaginationBar";
import {FilterContext} from "../../../context/FilterContext";
import ScorecardTable2 from "../../../components/ScorecardTable2";
import {PaginationContext} from "../../../context/PaginationContext";
import {JournalEditableScorecardContext} from "../context/JournalEditableScorecardContext";

const limit = 10

export default function JournalGroupAccordion(props) {
    //initial contexts
    const {filter} = useContext(FilterContext)

    //initial states
    const [groups, setGroups] = useState(null)

    //initial requests
    const getGroupsRequest = useRequest(ServerService.getJournal, {
        onPreLoad: () => setGroups(null),
        onSuccess: (response, groups) => setGroups(groups)
    })

    //initial effects
    useEffect(() => {
        getGroupsRequest.start({
            params: {
                '_search:student.fullName': filter.search,
                'teacher.userId': filter.teacherUserId,
                'student.faculty': filter.faculty,
                'student.group': filter.group,
                'creditIsPassed': filter.creditIsPassed,
                'healthCertificate.isActive': filter.healthCertificate,
            }
        })
    }, [filter])

    //build view
    return groups
        ? (
            <Stack
                spacing={0}
                divider={<Divider/>}
                overflowX={'hidden'} overflowY={'auto'}
                background={'white'}
                border={'1px'} borderColor={'gray.200'} borderRadius={'md'}
                {...props}>
                <JournalAccordionHeader/>
                {groups?.map(group => (
                    <JournalAccordionItem
                        key={group['_id']}
                        group={group}/>
                ))}
            </Stack>
        )
        : <SkeletonText isLoaded={groups} noOfLines={12} skeletonHeight={'45px'}/>
}

function JournalAccordionHeader(props) {
    const groupColumnProps = useMemo(() => ({
        color: 'gray.500',
        fontSize: 'xs',
        transition: 'ease 350ms',
        textTransform: 'uppercase'
    }), [])
    return (
        <Flex
            position={'sticky'} top={'0'}
            zIndex={'100'}
            columnGap={'10'}
            paddingX={'10'}
            paddingY={'3'}
            background={'gray.50'}
            boxShadow={'xs'}
            cursor={'pointer'}
            userSelect={'none'}
            _hover={{
                background: '#fafbfc'
            }}
            transition={'ease 350ms'}
            {...props}>
            <Text {...groupColumnProps} textAlign={'center'} flexBasis={'10%'}>Группа</Text>
            <Text {...groupColumnProps} flexBasis={'40%'}>Специализация</Text>
            <Text {...groupColumnProps} flexBasis={'40%'}>Преподаватели</Text>
            <Flex {...groupColumnProps} flexBasis={'44px'} alignItems={'center'} justifyContent={'center'}>Данные</Flex>
        </Flex>
    )
}

function JournalAccordionItem({group, ...props}) {
    //initial contexts
    const {filter} = useContext(FilterContext)
    const {editableScorecard, setEditableScorecard} = useContext(JournalEditableScorecardContext)

    //initial states
    const [scorecards, setScorecards] = useState(null)
    const [pagination, setPagination] = useState(null)
    const [page, setPage] = useState(1)
    const {isOpen, onOpen, onClose, onToggle} = useDisclosure()

    //initial requests
    const getScorecardRequest = useRequest(ServerService.getScorecards, {
        defaultConfig: {
            params: {
                'fksGroup.number': group["number"],
                "_page": page,
                "_limit": limit
            }
        },
        onPreLoad: _ => {
            setScorecards(null)
        },
        onSuccess: (response, scorecards) => {
            setScorecards(scorecards)
            setPagination(collectPagination(response.headers))
        }
    })

    //initial effects
    useEffect(() => {
        if (isOpen && !scorecards && !getScorecardRequest.isLoading)
            getScorecardRequest.start({
                params: {
                    '_search:student.fullName': filter.search,
                    'teacher.userId': filter.teacherUserId,
                    'student.faculty': filter.faculty,
                    'student.group': filter.group,
                    'creditIsPassed': filter.creditIsPassed,
                    'healthCertificate.isActive': filter.healthCertificate,
                }
            })
    })
    useEffect(() => {
        if (isOpen && scorecards && pagination)
            getScorecardRequest.start({
                params: {
                    '_search:student.fullName': filter.search,
                    'teacher.userId': filter.teacherUserId,
                    'student.faculty': filter.faculty,
                    'student.group': filter.group,
                    'creditIsPassed': filter.creditIsPassed,
                    'healthCertificate.isActive': filter.healthCertificate,
                    _page: page
                }
            })
    }, [filter, page])
    useEffect(() => {
        if (editableScorecard && scorecards?.find(({_id}) => _id === editableScorecard['_id']))
            getScorecardRequest.start()

    }, [editableScorecard])

    //initial props
    const groupColumnProps = useMemo(() => ({
        color: isOpen ? '#00487a' : 'gray.500',
        fontSize: '15px',
        transition: 'ease 350ms'
    }), [])
    const groupColumnStatus = useCallback(() => {
        if (getScorecardRequest.isLoading)
            return <Spinner
                thickness='3px'
                speed='0.65s'
                emptyColor='gray.200'
                color='#00487a'
                width={'18px'} height={'18px'}
            />
        else if (scorecards)
            return <DatabaseIcon width={'18px'} height={'18px'} stroke={'gray.300'}/>
        else
            return <CloudUploadIcon width={'18px'} height={'18px'} stroke={'gray.300'}/>
    }, [getScorecardRequest.isLoading, scorecards])

    //build view
    return (
        <PaginationContext.Provider value={pagination}>
            <Flex
                flexDir={'column'}
                border={'1px'} borderColor={isOpen ? '#00487a' : "transparent"} borderRadius={'sm'}
                paddingY={isOpen && '1'}
                transition={'ease 100ms'}
                {...props}>
                <Flex
                    onClick={onToggle}
                    columnGap={'10'}
                    paddingX={'10'}
                    paddingY={'3'}
                    cursor={'pointer'}
                    userSelect={'none'}
                    _hover={{background: '#fafbfc'}}
                    transition={'ease 350ms'}>
                    <Text {...groupColumnProps} textAlign={'center'} flexBasis={'10%'}>{group['number']}</Text>
                    <Text {...groupColumnProps} flexBasis={'40%'}>{group['specialization']['name']}</Text>
                    <Text {...groupColumnProps} flexBasis={'40%'}>{getJoinShortUserNames(group['teachers'])}</Text>
                    <Flex flexBasis={'44px'} alignItems={'center'} justifyContent={'center'}>{groupColumnStatus()}</Flex>
                </Flex>
                <Collapse in={isOpen} animateOpacity>
                    <Flex flexDir={'column'}>
                        {getScorecardRequest.isLoading &&
                            <SkeletonText noOfLines={limit} skeletonHeight={'43px'}/>
                        }
                        {scorecards &&
                            <ScorecardTable2
                                scorecards={scorecards}
                                onUpdate={scorecards => setScorecards(scorecards)}
                                onSelect={scorecard => setEditableScorecard(scorecard)}/>
                        }
                        {pagination &&
                            <Flex paddingX={'10'} paddingY={'3'} alignItems={'center'} justifyContent={'space-between'}>
                                <Tooltip label={'Общее колличество студентов'} placement={"end"}>
                                    <Text
                                        paddingX={'3'}
                                        paddingY={'1'}
                                        color={'gray.400'}
                                        fontSize={'sm'}
                                        fontWeight={'thin'}
                                        border={'1px'} borderColor={'gray.300'}
                                        rounded={'md'}
                                        userSelect={'none'}>
                                        {`${pagination['totalCount']} чел.`}
                                    </Text>
                                </Tooltip>
                                <PaginationBar
                                    pagination={pagination}
                                    onPageSelected={page => setPage(page)}/>
                            </Flex>
                        }
                    </Flex>
                </Collapse>
            </Flex>
        </PaginationContext.Provider>
    )
}
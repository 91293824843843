export function collectPagination(headers) {
    return {
        page: +headers['pagination-page'],
        limit: +headers['pagination-limit'],
        count: +headers['pagination-count'],
        totalPage: +headers['pagination-total-page'],
        totalCount: +headers['pagination-total-count']
    }
}

export function collectPaginationSerialNumber({page, limit, count, totalPage, totalCount}, position) {
    return (+page - 1) * +limit + +position
}
import React, {useContext, useEffect} from "react";
import {Flex, HStack, IconButton, Text, Tooltip, useToast} from "@chakra-ui/react";
import useRequest from "../../../hooks/RequestHook";
import ServerService from "../../../services/ServerService";
import {ArrowBackIcon, ChevronRightIcon} from "@chakra-ui/icons";
import UserIcon from "../../../components/icons/UserIcon";
import {shortUserName} from "../../../utils/environments";
import LogoutIcon from "../../../components/icons/LogoutIcon";
import {DashboardHeaderContext} from "../context/DashboardHeaderContext";
import {SessionContext} from "../../../context/SessionContext";
import {DashboardNavigationContext} from "../context/DashboardNavigationContext";

export default function DashboardFragmentHeader(props) {
    const session = useContext(SessionContext)
    const {navigation} = useContext(DashboardNavigationContext)
    const {title, setTitle, subTitle, setSubTitle, onButtonBack, setOnButtonBack} = useContext(DashboardHeaderContext)

    const toast = useToast()

    const deleteSessionRequest = useRequest(ServerService.unLogin, {
        onSuccess: () => window.location.reload(),
        onError: () => {
            toast({
                title: 'Ошибка закрытия сессии',
                description: 'Возможно сервер не доступен',
                status: 'error',
                duration: 1500,
                isClosable: true,
                position: 'top-right'
            })
        }
    })

    const onLogoutAction = () => deleteSessionRequest.start()

    useEffect(() => {
        setTitle(navigation?.link?.label ?? "")
    }, [navigation])

    return (
        <Flex alignItems={'center'} justifyContent={'space-between'} {...props}>
            <HStack spacing={'3'} alignItems={'center'}>
                {onButtonBack &&
                    <Tooltip label={'Назад'}>
                        <ArrowBackIcon
                            width={'24px'} height={'24px'}
                            color={'gray.300'}
                            _hover={{color: '#00487a'}}
                            cursor={'pointer'}
                            onClick={() => {
                                onButtonBack && onButtonBack()
                                setOnButtonBack(null)
                                setSubTitle('')
                            }}
                            transition={'150ms ease'}
                        />
                    </Tooltip>
                }
                <Text
                    spacing={'1.5'}
                    divider={<ChevronRightIcon border={'none'}/>}
                    color={'#00487a'}
                    fontSize={'24px'}
                    fontWeight='light'
                    userSelect={'none'}>
                    {title}
                </Text>
            </HStack>
            <HStack spacing={{base: '1', lg: '3'}} alignItems={'center'}>
                <UserIcon stroke={'gray.600'}/>
                <Text color={'gray.600'} fontSize={{base: 'sm', lg: 'lg'}} fontWeight={'medium'} userSelect={'none'}>{shortUserName(session)}</Text>
                <Tooltip label={'Выйти из аккаунта'}>
                    <IconButton
                        size={{base: 'sm', lg: 'md'}}
                        role={'group'}
                        icon={<LogoutIcon stroke={'gray.300'} _groupHover={{stroke: 'gray.600'}} transition={'ease 300ms'}/>}
                        background={'gray.100'}
                        aria-label={'Выйти из аккаунта'}
                        _groupHover={{background: 'gray.300'}}
                        transition={'ease 300ms'}
                        onClick={onLogoutAction}/>
                </Tooltip>
            </HStack>
        </Flex>
    )
}
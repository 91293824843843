import React, {useContext, useEffect, useState} from 'react';
import useRequest from "../../hooks/RequestHook";
import ServerService from "../../services/ServerService";
import {Flex, Heading, HStack, SkeletonText, Stack, Switch, Text, useToast} from "@chakra-ui/react";
import {SessionContext} from "../../context/SessionContext";
import ScorecardHeader from "./components/ScorecardHeader";
import ScorecardHealthCertificate from "./components/ScorecardHealthCertificate";
import ScorecardNoteArea from "./components/ScorecardNoteArea";
import ScorecardAttendanceList from "./components/ScorecardAttendanceList";
import ScorecardInfo from "./components/ScorecardInfo";

export default function ScorecardFragment({initialScorecard, onUpdate = (scorecard) => undefined, isEditable = false, ...props}) {
    //initial components
    const toast = useToast()

    //initial contexts
    const session = useContext(SessionContext)

    //initial props
    const studentId = (session['role'] === 'Student' ? session['userId'] : null)

    //initial states
    const [scorecard, setScorecard] = useState(initialScorecard)

    //initial requests
    const getScorecardRequest = useRequest(ServerService.getScorecardByStudentId, {
        onPreLoad: () => setScorecard(null),
        onSuccess: (response, scorecard) => setScorecard(scorecard)
    })
    const patchScorecardRequest = useRequest(ServerService.patchScorecard, {
        defaultConfig: {
            headers: {"Content-Type": "application/json"}
        },
        onSuccess: () => {
            toast({
                title: `Данные обновлены`,
                position: 'top-right',
                status: 'success',
                isClosable: true,
            })
        },
        onError: (message) => {
            toast({
                title: `Ошибка обновления данных`,
                position: 'top-right',
                status: 'error',
                isClosable: true,
            })
        }
    })

    //initial actions
    const onSaveScorecard = () => {
        const {_id, fksGroup: group, teacher, ...scorecardProps} = scorecard

        if (group && teacher)
            patchScorecardRequest.start({
                id: _id,
                data: JSON.stringify({
                    ...scorecardProps,
                    fksGroupId: group['_id'],
                    teacher: teacher
                }),
            }, {
                onSuccessCallBack: () => onUpdate(scorecard)
            })
    }

    //initial effects
    useEffect(() => {
        if (!initialScorecard && studentId)
            getScorecardRequest.start({studentId})
    }, [studentId])

    //build view
    return !getScorecardRequest.error
        ? (
            <SkeletonText isLoaded={scorecard} skeletonHeight={8} noOfLines={15} height={'100%'} overflowY={'auto'}>
                {scorecard &&
                    <Stack spacing={'5'} height={'100%'} overflow={'hidden'} {...props}>
                        <ScorecardHeader
                            userName={scorecard['student']['fullName']}
                            onSave={onSaveScorecard}
                            isDisabled={getScorecardRequest.isLoading || patchScorecardRequest.isLoading}
                            isEditable={isEditable}/>
                        <Flex flexDirection='column' rowGap={8} overflowY={'auto'}>
                            <Stack spacing={'5'}>
                                <Heading color={'gray.600'} fontSize='md'>Основная информация</Heading>
                                <ScorecardInfo
                                    scorecard={scorecard}
                                    onUpdate={scorecard => setScorecard(scorecard)}
                                    isDisabled={getScorecardRequest.isLoading || patchScorecardRequest.isLoading}
                                    isEditable={isEditable}/>
                            </Stack>
                            {isEditable &&
                                <Stack spacing={'5'}>
                                    <HStack>
                                        <Switch
                                            size={'sm'}
                                            isDisabled={patchScorecardRequest.isLoading}
                                            isChecked={scorecard['healthCertificate']['isActive']}
                                            onChange={event => setScorecard(prev => {
                                                const healthCertificate = {...prev['healthCertificate'], isActive: event.target.checked}
                                                return {...prev, healthCertificate}
                                            })}/>
                                        <Heading color={'gray.600'} fontSize='md'>Справка</Heading>
                                    </HStack>
                                    <ScorecardHealthCertificate
                                        healthCertificate={scorecard['healthCertificate']}
                                        onUpdate={healthCertificate => setScorecard({...scorecard, healthCertificate})}
                                        isDisabled={getScorecardRequest.isLoading || patchScorecardRequest.isLoading}/>
                                </Stack>
                            }
                            {isEditable &&
                                <Stack spacing={'5'}>
                                    <Heading color={'gray.600'} fontSize='md'>Дополнительная информация</Heading>
                                    <ScorecardNoteArea
                                        scorecard={scorecard}
                                        onUpdate={scorecard => setScorecard(scorecard)}
                                        isDisabled={getScorecardRequest.isLoading || patchScorecardRequest.isLoading}/>
                                </Stack>
                            }
                            <Stack spacing={'5'}>
                                <Heading color={'gray.600'} fontSize='md'>Посещаемость</Heading>
                                <ScorecardAttendanceList
                                    attendance={scorecard['attendance']}
                                    onUpdate={attendance => setScorecard({...scorecard, attendance})}
                                    isDisabled={getScorecardRequest.isLoading || patchScorecardRequest.isLoading}
                                    isEditable={isEditable}/>
                            </Stack>
                        </Flex>
                    </Stack>
                }
            </SkeletonText>
        )
        : <Text color={'gray.400'} fontSize={'md'}>{getScorecardRequest?.error?.response?.data ?? "Ошибка"}</Text>
}
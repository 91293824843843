import React, {useCallback, useRef, useState} from 'react';
import {
    Button,
    FormControl,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useToast
} from "@chakra-ui/react";
import useRequest from "../../../hooks/RequestHook";
import ServerService from "../../../services/ServerService";

export default function EditSpecializationModal(
    {
        specialization = null,
        controller,
        onCreate = (specialization) => undefined,
        onEdit = (specialization) => undefined,
        onCancel = () => undefined,
        ...props
    }
) {
    //initial components
    const toast = useToast()

    //initial refs
    const nameInputRef = useRef()

    //initial states
    const [name, setName] = useState(specialization?.name ?? '')
    const [abbreviation, setAbbreviation] = useState(specialization?.abbreviation ?? '')

    //initial requests
    const postSpecializationRequest = useRequest(ServerService.postSpecialization, {})
    const patchSpecializationRequest = useRequest(ServerService.patchSpecialization, {
        onError: () => (
            toast({
                title: specialization['name'],
                description: `Ошибка изменения специализации`,
                position: 'top-right',
                status: 'error',
                isClosable: true,
            })
        )
    })

    //initial actions
    const onSaveSpecialization = useCallback(() => {
        if (name.length < 1 || abbreviation.length < 1) {
            toast({
                title: 'Поля ввода не могут быть пустыми',
                position: 'top-right',
                status: 'warning',
                isClosable: true,
            })
            return
        }

        if (name.length < 1 || abbreviation.length < 1) {
            toast({
                title: 'Поля ввода не могут быть пустыми',
                position: 'top-right',
                status: 'warning',
                isClosable: true,
            })
            return
        }

        if (specialization) {
            if (specialization['name'] === name && specialization['abbreviation'] === abbreviation) {
                toast({
                    title: specialization['name'],
                    description: `Специализация не была изменена, так как изменения отсутствуют`,
                    position: 'top-right',
                    status: 'warning',
                    isClosable: true,
                })
                return
            }

            const editedSpecialization = {...specialization, name, abbreviation}
            patchSpecializationRequest.start({id: specialization['_id'], data: editedSpecialization}, {
                onSuccessCallBack: (response, data) => {
                    controller.onClose()
                    onEdit(editedSpecialization)
                    toast({
                        title: specialization['name'],
                        description: `Специализация изменена на ${editedSpecialization['name']} [${editedSpecialization['abbreviation']}]`,
                        position: 'top-right',
                        status: 'success',
                        isClosable: true,
                    })
                }
            })
        } else {
            const newSpecialization = {_id: '', name, abbreviation}
            postSpecializationRequest.start({data: newSpecialization}, {
                onSuccessCallBack: (response, specialization) => {
                    controller.onClose()
                    onCreate(specialization)
                    toast({
                        title: newSpecialization['name'],
                        description: `Специализация ${specialization['name']} [${specialization['abbreviation']}] добавлена`,
                        position: 'top-right',
                        status: 'success',
                        isClosable: true,
                    })
                },
                onErrorCallBack: () => (
                    toast({
                        title: newSpecialization['name'],
                        description: `Ошибка добавления специализации`,
                        position: 'top-right',
                        status: 'error',
                        isClosable: true,
                    })
                )
            })
        }
    }, [specialization, name, abbreviation])

    //build view
    return (
        <Modal
            initialFocusRef={nameInputRef}
            returnFocusOnClose={false}
            closeOnOverlayClick={false}
            isOpen={controller.isOpen}
            onClose={controller.onClose}
            {...props}>
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader userSelect={'none'}>{specialization ? 'Редактирование специализации' : 'Добавление специализации'}</ModalHeader>
                <ModalCloseButton isDisabled={postSpecializationRequest.isLoading || patchSpecializationRequest.isLoading}/>
                <ModalBody pb={3}>
                    <FormControl>
                        <FormLabel userSelect={'none'}>Наименование</FormLabel>
                        <Input
                            ref={nameInputRef}
                            placeholder='Введите наименование'
                            value={name}
                            onInput={event => setName(event.target['value'])}
                            isDisabled={postSpecializationRequest.isLoading || patchSpecializationRequest.isLoading}/>
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel userSelect={'none'}>Аббревиатура</FormLabel>
                        <Input
                            placeholder='Введите аббревиатуру'
                            value={abbreviation}
                            onInput={event => setAbbreviation(event.target['value'])}
                            isDisabled={postSpecializationRequest.isLoading || patchSpecializationRequest.isLoading}/>
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <Button
                        colorScheme='blue'
                        width={'100%'}
                        mr={3}
                        onClick={onSaveSpecialization}
                        isLoading={postSpecializationRequest.isLoading || patchSpecializationRequest.isLoading}>{specialization ? 'Редактировать' : 'Добавить'}</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
import {useEffect, useState} from "react";
import ServerService from "../services/ServerService";
import useRequest from "./RequestHook";

export default function useSession() {
    const [session, setSession] = useState(null)

    const getSessionRequest = useRequest(ServerService.getSession, {
        onPreLoad: () => setSession(null),
        onSuccess: (response, session) => setSession(session),
    })

    useEffect(() => {
        if (!session)
            getSessionRequest.start()
    }, [])

    return session
}
import React from "react";
import {Textarea} from "@chakra-ui/react";

export default function ScorecardNoteArea({scorecard, onUpdate = (scorecard) => undefined, isDisabled = false, ...props}) {
    return (
        <Textarea
            placeholder='Дополнительная информация'
            value={scorecard['note']}
            onInput={event => onUpdate({...scorecard, note: event.target['value']})}
            fontSize='0.9rem'
            resize='none'
            background={'white'}
            isDisabled={isDisabled}
            {...props}
        />
    )
}
import React from 'react';
import {Icon} from "@chakra-ui/react";

const CloudUploadIcon = (props) => (
    <Icon xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" strokeWidth="2" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round" {...props}>
        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
        <path d="M7 18a4.6 4.4 0 0 1 0 -9a5 4.5 0 0 1 11 2h1a3.5 3.5 0 0 1 0 7h-1"/>
        <polyline points="9 15 12 12 15 15"/>
        <line x1="12" y1="12" x2="12" y2="21"/>
    </Icon>
)

export default CloudUploadIcon;
import React, {useCallback} from 'react';
import {Button, Flex, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useToast} from "@chakra-ui/react";
import useRequest from "../../../hooks/RequestHook";
import ServerService from "../../../services/ServerService";

export default function ChangeFKSGroupNumberModal({group, editableNumber, controller, onAccept = () => undefined, onCancel = () => undefined, ...props}) {
    //initial components
    const toast = useToast()

    //build view
    return (
        <Modal
            closeOnOverlayClick={false}
            returnFocusOnClose={false}
            isOpen={controller.isOpen}
            onClose={controller.onClose}
            {...props}>
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader userSelect={'none'}>Изменение номера группы</ModalHeader>
                <ModalBody pb={6} userSelect={'none'}>
                    <Text>Вы действительно хотите изменить номер группы <Text as={'span'} color={'gray.700'} fontWeight={'medium'}>{group.number}</Text> на <Text as={'span'} color={'blue.700'} fontWeight={'medium'}>{editableNumber}</Text> ?</Text>
                </ModalBody>
                <ModalFooter>
                    <Button
                        colorScheme='blue'
                        width={'100%'}
                        mr={3}
                        onClick={() => {
                            controller.onClose()
                            onAccept()
                        }}>Изменить</Button>
                    <Button
                        width={'100%'}
                        onClick={() => {
                            controller.onClose()
                            onCancel()
                        }}>Отмена</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
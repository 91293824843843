import React, {useContext, useEffect, useState} from "react";
import {SimpleTable, SimpleTableItem} from "../../../components/SimpleTable";
import {Input, Select, Skeleton, Switch, Text} from "@chakra-ui/react";
import useRequest from "../../../hooks/RequestHook";
import ServerService from "../../../services/ServerService";
import {SessionContext} from "../../../context/SessionContext";

export default function ScorecardInfo({scorecard, onUpdate = (scorecard) => undefined, isEditable = false, isDisabled = false, ...props}) {
    //initial contexts
    const session = useContext(SessionContext)

    //initial states
    const [specializations, setSpecializations] = useState(null)
    const [specializationIdSelected, setSpecializationIdSelected] = useState(scorecard?.fksGroup?.specialization?._id ?? "")
    const [groups, setGroups] = useState(null)
    const [groupIdSelected, setGroupIdSelected] = useState(scorecard?.fksGroup?._id ?? "")
    const [teachers, setTeachers] = useState(scorecard?.fksGroup?.teachers ?? [])
    const [teacherUserSelected, setTeacherUserIdSelected] = useState(scorecard?.teacher?.userId ?? "")

    //initial requests
    const getSpecializationsRequest = useRequest(ServerService.getSpecializations, {
        onPreLoad: () => setSpecializations(null),
        onSuccess: (response, specializations) => {
            const specializationSelected = specializations.find(({_id}) => _id === specializationIdSelected)

            setSpecializations(specializations)
            setSpecializationIdSelected(specializationSelected ? specializationSelected['_id'] : specializations[0]['_id'])
        }
    })
    const getGroupsRequest = useRequest(ServerService.getFKSGroups, {
        onPreLoad: () => setGroups(null),
        onSuccess: (response, groups) => {
            const groupSelected = groups.find(({_id}) => _id === groupIdSelected)

            setGroups(groups)
            setGroupIdSelected(groupSelected ? groupSelected['_id'] : groups[0]['_id'])
        }
    })
    const getTeachersRequest = useRequest(ServerService.getTeachers, {
        onPreLoad: () => setTeachers(null),
        onSuccess: (response, teachers) => {
            const teacherSelected = teachers.find(({userId}) => userId === teacherUserSelected)

            setTeachers(teachers)
            setTeacherUserIdSelected(teacherSelected ? teacherSelected['userId'] : teachers[0]['userId'])
        }
    })

    //initial effects
    //---load-effects
    useEffect(() => {
        getSpecializationsRequest.start()
    }, [])
    useEffect(() => {
        if (specializationIdSelected)
            getGroupsRequest.start({
                params: {
                    'specialization._id': specializationIdSelected
                }
            })
    }, [specializationIdSelected])
    useEffect(() => {
        if (groups && groupIdSelected) {
            const teacherUseIds = groups
                ?.find(({_id}) => _id === groupIdSelected)
                ?.teachers
                ?.map(({userId}) => userId)
                ?.join(',')

            getTeachersRequest.start({
                params: {
                    'userId': teacherUseIds
                }
            })
        }
    }, [groupIdSelected])
    //---update-scorecard-effects
    useEffect(() => {
        if (scorecard['fksGroup']['_id'] !== groupIdSelected) {
            const group = groups?.find(({_id}) => _id === groupIdSelected)
            const teacher = group?.teachers[0]
            group && onUpdate({...scorecard, group, teacher})
        }
    }, [groupIdSelected])
    useEffect(() => {
        if (scorecard['teacher']['userId'] !== teacherUserSelected) {
            const teacher = teachers?.find(({userId}) => userId === teacherUserSelected)
            teacher && onUpdate({...scorecard, teacher})
        }
    }, [teacherUserSelected])

    return (
        <SimpleTable
            padding={'5'}
            background={'white'}
            border={'1px'} borderColor={'gray.200'}
            rounded={'md'}
            {...props}>
            <SimpleTableItem label='Факультет' cell={scorecard['student']['faculty']}/>
            <SimpleTableItem label='Специальность' cell={scorecard['student']['specialty']}/>
            <SimpleTableItem label='Уровень обучения' cell={scorecard['student']['educationLevel']}/>
            <SimpleTableItem label='Тип обучения' cell={scorecard['student']['typeOfEducation']}/>
            <SimpleTableItem label='Академическая группа' cell={scorecard['student']['group']}/>
            <SimpleTableItem label='Наличие справки о состоянии здоровья' cell={
                scorecard['healthCertificate']['isActive'] ? `${scorecard['healthCertificate']['number']} - ${scorecard['healthCertificate']['healthGroup']} группа` : 'Отсутствует'
            }/>
            <SimpleTableItem
                label='Наличие зачета по дисциплине'
                cell={
                    isEditable
                        ? <Switch
                            isDisabled={isDisabled}
                            isChecked={scorecard['creditIsPassed']}
                            onChange={event => onUpdate({...scorecard, creditIsPassed: event.target.checked})}/>
                        : scorecard['creditIsPassed'] ? 'Зачтено' : 'Отсутствует'
                }
            />
            <SimpleTableItem label='Специальность' cell={
                isEditable && session['role'] === 'Admin'
                    ? (
                        <Skeleton isLoaded={specializations} height={'40px'}>
                            {specializations &&
                                <Select
                                    isDisabled={isDisabled}
                                    value={specializationIdSelected}
                                    onChange={event => setSpecializationIdSelected(event.target.value)}>
                                    {specializations?.map(({_id, name}) =>
                                        <option key={_id} value={_id}>
                                            {name}
                                        </option>
                                    )}
                                </Select>
                            }
                        </Skeleton>
                    )
                    : <Text>{scorecard['fksGroup']['specialization']['name']}</Text>
            }/>
            <SimpleTableItem label='ФКС группа' cell={
                isEditable && session['role'] === 'Admin'
                    ? (
                        <Skeleton isLoaded={specializations && groups} height={'40px'}>
                            {groups &&
                                <Select
                                    isDisabled={isDisabled}
                                    value={groupIdSelected}
                                    onChange={event => setGroupIdSelected(event.target.value)}>
                                    {groups?.map(({_id, number}) =>
                                        <option key={_id} value={_id}>
                                            {number}
                                        </option>
                                    )}
                                </Select>
                            }
                        </Skeleton>
                    )
                    : <Text>{scorecard['fksGroup']['number']}</Text>
            }/>
            <SimpleTableItem label='Преподаватель' cell={
                isEditable && session['role'] === 'Admin'
                    ? (
                        <Skeleton isLoaded={specializations && groups && teachers} height={'40px'}>
                            {teachers &&
                                <Select
                                    isDisabled={isDisabled}
                                    value={teacherUserSelected}
                                    onChange={event => setTeacherUserIdSelected(event.target.value)}>
                                    {teachers?.map(({userId, fullName}) =>
                                        <option key={userId} value={userId}>
                                            {fullName}
                                        </option>
                                    )}
                                </Select>
                            }
                        </Skeleton>
                    )
                    : <Text>{scorecard['teacher']['fullName']}</Text>
            }/>
            <SimpleTableItem label='Дополнительные баллы' cell={
                isEditable
                    ? <Input
                        isDisabled={isDisabled}
                        value={`${scorecard['extraPoints']}`}
                        onInput={event => {
                            const input = event.target['value'].trim()
                            const points = input === "" ? 0 : +input

                            if (!isNaN(points) && (points >= 0 && points <= 100))
                                onUpdate({...scorecard, extraPoints: points})
                            else
                                onUpdate({...scorecard, extraPoints: 0})
                        }}
                        onFocus={(event) => event.target.select()}
                        type={'number'}
                        width={'60px'}/>
                    : scorecard['extraPoints']
            }/>
            <SimpleTableItem label='Всего баллов' cell={scorecard['attendance'].reduce((acc, {grade}) => acc + grade, 0) + scorecard['extraPoints']}/>
        </SimpleTable>
    )
}
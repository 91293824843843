import React from 'react';
import {Box, Divider, HStack, IconButton, Stack, Text, Tooltip, useDisclosure, useToast} from "@chakra-ui/react";
import {SmallCloseIcon} from "@chakra-ui/icons";
import SearchTeacherModal from "../fragments/groups/components/SearchTeacherModal";
import {shortUserName} from "../utils/environments";

const TeacherEditor = ({teachers, onInsert = (teacher) => undefined, onChange = (teachers) => undefined, ...props}) => {
    //initial components
    const toast = useToast()

    //initial states
    const searchModalController = useDisclosure()

    //initial actions
    const onAddTeacherAction = teacher => {
        const teacherIsExist = teachers.find(_teacher => _teacher["userId"] === teacher["userId"])

        if (teacherIsExist)
            toast({
                title: 'Преподаватель не добавлен',
                description: `Преподаватель ${shortUserName(teacher)}. уже существует в группе`,
                status: 'error',
                position: 'top-right',
                duration: 9000,
                isClosable: true,
            })
        else {
            onInsert(teacher)
            onChange([...teachers, teacher])
        }
    }
    const onRemoveTeacherAction = teacher => {
        onChange(
            teachers.filter(_teacher =>
                _teacher['userId'] !== teacher["userId"]
            )
        )
    }

    //initial view
    return <Box {...props}>
        <Stack spacing={'1.5'} divider={<Divider/>}>
            {teachers?.map((teacher, index) =>
                <HStack key={teacher["userId"]} spacing={3}>
                    <Text color="gray.600" fontSize={'md'}>{`${index + 1}.`}</Text>
                    <Text color="gray.600" fontSize={'md'} fontWeight="medium" flexShrink={1} flexGrow={1}>{teacher["fullName"]}</Text>
                    <Tooltip label="Удалить преподавателя из группы">
                        <IconButton
                            icon={<SmallCloseIcon width={'18px'} height={'18px'}/>}
                            aria-label="Удалить преподавателя"
                            color="gray.400"
                            size="xs"
                            background={"none"}
                            onClick={() => onRemoveTeacherAction(teacher)}/>
                    </Tooltip>
                </HStack>
            )}
            <HStack
                spacing={'3'}
                color="gray.300"
                fontSize={'md'}
                userSelect="none"
                cursor="pointer"
                _hover={{color: 'gray.500'}}
                onClick={searchModalController.onOpen}
                transition={"ease 150ms"}>
                <Text>{`${teachers?.length + 1 ?? 1}.`}</Text>
                <Text>Добавить преподавателя</Text>
            </HStack>
        </Stack>
        <SearchTeacherModal controller={searchModalController} onSelect={onAddTeacherAction}/>
    </Box>
}

export default TeacherEditor;
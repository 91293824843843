import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Grid, HStack, IconButton, SkeletonText, Stack, Text, Tooltip, useDisclosure} from "@chakra-ui/react";
import useRequest from "../../hooks/RequestHook";
import ServerService from "../../services/ServerService";
import FKSGroupEditor from "./components/FKSGroupEditor";
import SearchInput from "../../components/SearchInput";
import SquareAddIcon from "../../components/icons/SquareAddIcon";
import CreateFKSGroupModal from "./components/CreateFKSGroupModal";

export default function FKSGroupFragment(props) {
    //initial refs
    const groupsRef = useRef(null)

    //initial states
    const [groups, setGroups] = useState(null)
    const [specializations, setSpecializations] = useState(null)
    const [searchQuery, setSearchQuery] = useState('')
    const createGroupModalController = useDisclosure()

    //initial requests
    const getGroupsRequest = useRequest(ServerService.getFKSGroups, {
        onPreLoad: () => setGroups(null),
        onSuccess: (response, groups) => {
            groupsRef.current = groups
            onSortedGroupsAction(groups)
        }
    })
    const getSpecializationsRequest = useRequest(ServerService.getSpecializations, {
        onPreLoad: () => setSpecializations(null),
        onSuccess: (response, specializations) => setSpecializations(specializations)
    })

    //initial actions
    const onSortedGroupsAction = useCallback((groups) => {
        setGroups(
            groups?.sort((x, y) => x['number'] > y['number'] ? 1 : -1)
        )
    }, [])
    const onGroupUpdateAction = group => {
        onSortedGroupsAction(
            groups.map(_group =>
                _group['_id'] === group['_id'] ? group : _group
            )
        )
    }

    //initial effects
    useEffect(() => {
        getGroupsRequest.start()
        getSpecializationsRequest.start()
    }, [])
    useEffect(() => {
        onSortedGroupsAction(
            searchQuery > 0
                ? groupsRef.current.filter(_group => {
                    const regex = new RegExp(searchQuery, 'i')
                    return !!regex.exec(_group['number'])
                })
                : groupsRef.current
        )
    }, [searchQuery])

    //initial view
    return (
        <>
            {groups && specializations
                ? <Stack spacing={'3'} {...props}>
                    <HStack>
                        <SearchInput
                            initialValue={searchQuery}
                            placeholder={'Поиск по номеру группы'}
                            type={'number'}
                            onInput={value => {
                                setSearchQuery(`${+value}`)
                            }}
                            flex={'1 0 0'}/>
                        <Tooltip label={'Добавить группу'}>
                            <IconButton
                                role={'group'}
                                icon={<SquareAddIcon stroke={'gray.200'} strokeWidth={'2px'} transition={'250ms ease'} _groupHover={{stroke: 'blue.700'}}/>}
                                aria-label={'Добавить группу'}
                                onClick={() => createGroupModalController.onOpen()}
                                background={"white"}
                                border={'1px'} borderColor={'gray.200'}
                                _hover={{background: 'gray.50'}}/>
                        </Tooltip>
                    </HStack>
                    {
                        groups?.length > 0
                            ? <Grid
                                gridTemplateColumns={{base: "1fr", lg: "repeat(2, 1fr)", "2xl": "repeat(3, 1fr)"}}
                                gridAutoRows="max-content"
                                gridGap={3}
                                alignItems="top">
                                {groups?.map(group =>
                                    <FKSGroupEditor
                                        key={group["_id"]}
                                        group={group}
                                        specializations={specializations}
                                        onUpdate={onGroupUpdateAction}
                                        onDelete={group =>
                                            setGroups(groups =>
                                                groups.filter(({_id}) => _id !== group['_id'])
                                            )
                                        }/>
                                )}
                            </Grid>
                            : <Text color={'gray.300'} fontSize={'sm'} textAlign={'center'} textTransform={'uppercase'}>Список ФКС групп пуст</Text>
                    }
                </Stack>
                : <SkeletonText skeletonHeight={'10'} noOfLines={15} flex={'1 1 0'} overflowX={'hidden'} overflowY={'auto'}/>
            }
            {createGroupModalController.isOpen &&
                <CreateFKSGroupModal
                    controller={createGroupModalController}
                    specializations={specializations}
                    onCreated={group => {
                        onSortedGroupsAction([...groups, group])
                    }}/>
            }
        </>
    )
}
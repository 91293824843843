import {Box, Collapse, Flex, HStack, IconButton, Input, Skeleton, Switch, Text, Tooltip, useDisclosure, useToast} from "@chakra-ui/react";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {AddIcon} from "@chakra-ui/icons";
import CreateAcademicGroupModal from "./CreateAcademicGroupModal";
import RemoveIcon from "../../../components/icons/RemoveIcon";
import useRequest from "../../../hooks/RequestHook";
import ServerService from "../../../services/ServerService";
import DeleteAcademicGroupModal from "./DeleteAcademicGroupModal";
import {shortUserName} from "../../../utils/environments";

export default function FKSGroupCollapse({fksGroup, index, ...props}) {
    //initial states
    const [academicGroups, setAcademicGroups] = useState([])
    const {isOpen, onToggle} = useDisclosure()
    const createModalController = useDisclosure()

    //initial values
    const totalQuotas = useMemo(() => (
        academicGroups.reduce((total, {quota}) => total + +quota, 0)
    ), [academicGroups])

    //initial action
    const onSortAcademicGroups = useCallback((academicGroups) => (
        setAcademicGroups(
            academicGroups?.sort((x, y) => x['number'] > y['number'] ? 1 : -1)
        )
    ), [])

    //initial effects
    useEffect(() => {
        if (fksGroup['academicGroups'])
            onSortAcademicGroups(fksGroup['academicGroups'])
    }, [])

    //initial view
    return (
        <Box
            overflow={'hidden'}
            my={'1'}
            bg={'white'}
            rounded={'md'}
            border={'1px'} borderColor={isOpen ? 'blue.500' : 'gray.200'}
            _hover={!isOpen && {borderColor: 'blue.500'}}
            transition={'ease 150ms'}
            {...props}>
            <HStack
                role={'button'}
                spacing={'4'}
                zIndex={'100'}
                alignItems={'start'}
                flex='1'
                p={'3'}
                bg={'white'}
                borderBottom={'1px'} borderBottomColor={isOpen ? 'gray.200' : 'transparent'}
                onClick={onToggle}>
                <HStack spacing={'4'}>
                    <Tooltip label={'ФКС группа'}>
                        <Box>
                            <Text
                                color={isOpen ? 'blue.50' : 'blue.500'}
                                bg={isOpen ? 'blue.500' : 'transparent'}
                                border={'1px'} borderColor={'blue.500'} borderRadius={'md'}
                                userSelect={'none'}
                                flex={'1'}
                                px={'3'}>{fksGroup.number}</Text>
                        </Box>
                    </Tooltip>
                    <Tooltip label={'Общее количество доступных мест'}>
                        <Text color={'gray.300'} fontSize={'sm'}>{`${totalQuotas} ч.`}</Text>
                    </Tooltip>
                </HStack>
                <Flex flex={'1'} gap={'2'} wrap={'wrap'} justifyContent={'start'}>
                    {fksGroup?.teachers?.map(teacher =>
                        <Tooltip key={teacher['userId']} label={'Преподаватель группы'}>
                            <Text
                                color={'gray.400'}
                                fontSize={'sm'}
                                px={'1.5'} py={'0.5'}
                                bg={'gray.100'}
                                borderRadius={'md'}
                                userSelect={'none'}>{shortUserName(teacher)}</Text>
                        </Tooltip>
                    )}
                </Flex>
                <Flex flex={'1'} gap={'2'} wrap={'wrap'} justifyContent={'end'}>
                    {academicGroups?.map(academicGroup =>
                        <Tooltip key={academicGroup['number']} label={'Академическая группа'}>
                            <Text
                                color={'gray.400'}
                                fontSize={'sm'}
                                px={'1.5'} py={'0.5'}
                                bg={'gray.100'}
                                borderRadius={'md'}
                                userSelect={'none'}>{academicGroup['number']}</Text>
                        </Tooltip>
                    )}
                </Flex>
            </HStack>
            <Collapse in={isOpen} animateOpacity>
                <Flex
                    wrap={'wrap'}
                    gap={'3'}
                    maxH={'480px'}
                    overflowX={'hidden'} overflowY={'auto'}
                    px={'6'} py={'3'}
                    bg={'#fafbfc'}>
                    {academicGroups?.map((academicGroup, index) => (
                        <AcademicGroupItem
                            key={academicGroup['number']}
                            fksGroup={fksGroup}
                            academicGroup={academicGroup}
                            index={index}
                            onRemoved={academicGroup => (
                                setAcademicGroups(groups =>
                                    groups.filter(({number}) => number !== academicGroup['number'])
                                )
                            )}
                            onUpdated={academicGroup => (
                                onSortAcademicGroups(
                                    academicGroups.map((group) =>
                                        group['number'] === academicGroup['number'] ? academicGroup : group
                                    )
                                )
                            )}/>
                    ))}
                    <Tooltip label={'Добавить академическую группу'}>
                        <IconButton
                            icon={<AddIcon color={'gray.400'}/>}
                            aria-label={'Добавить академическую группу'}
                            w={'45px'} h={'45px'}
                            variant={'unstyled'}
                            display={'flex'}
                            px={'2.5'} py={'1.5'}
                            bg={'white'}
                            rounded={'lg'}
                            shadow={'base'}
                            alignItems={'center'}
                            _hover={{bg: 'gray.50'}}
                            onClick={() => createModalController.onOpen()}/>
                    </Tooltip>
                </Flex>
            </Collapse>
            {createModalController.isOpen && (
                <CreateAcademicGroupModal
                    controller={createModalController}
                    fksGroup={fksGroup}
                    onCreated={academicGroup => onSortAcademicGroups([...academicGroups, academicGroup])}/>
            )}
        </Box>
    )
}

function AcademicGroupItem({fksGroup, academicGroup, onUpdated = (academicGroup) => undefined, onRemoved = (academicGroup) => undefined}) {
    //initial components
    const toast = useToast()

    //initial states
    const [quota, setQuota] = useState(academicGroup['quota'])
    const [isEnrollAvailable, setIsEnrollAvailable] = useState(academicGroup['isEnrollAvailable'])
    const removeAcademicGroupController = useDisclosure()

    //initial requests
    const putAcademicGroupRequest = useRequest(ServerService.putAcademicGroup, {
        defaultConfig: {
            id: fksGroup['_id'],
            number: academicGroup['number']
        },
        onSuccess: () => {
            toast({
                title: `Группа ${academicGroup['number']} обновлена`,
                position: 'top-right',
                status: 'success',
                isClosable: true,
            })
        },
        onError: (error) => (
            toast({
                title: `Ошибка обновления группы ${academicGroup['number']}`,
                description: error?.response?.data ?? "Неизвестная ошибка",
                position: 'top-right',
                status: 'error',
                isClosable: true,
            })
        )
    })

    //initial actions
    const onInputQuota = useCallback((value) => {
        const quota = value === '' ? 0 : +value
        if (quota < 1000)
            setQuota(`${quota}`)
    }, [])
    const onUpdateAcademicGroup = useCallback((updatedFields) => {
        const updatedAcademicGroup = {...academicGroup, ...updatedFields}

        putAcademicGroupRequest.start({data: updatedAcademicGroup}, {
            onSuccessCallBack: (response, data) => onUpdated(updatedAcademicGroup)
        })
    }, [quota, isEnrollAvailable])

    //build view
    return (
        <>
            <HStack spacing={'1'} px={'2.5'} py={'1.5'} bg={'white'} rounded={'lg'} shadow={'base'}>
                <Tooltip label={'Академическая группа'}>
                    <Text color={'gray.400'} px={'1.5'} py={'0.5'} bg={'gray.100'} borderRadius={'md'} userSelect={'none'}>{academicGroup['number']}</Text>
                </Tooltip>
                <Tooltip label={'Количество доступных мест'}>
                    <Skeleton isLoaded={!putAcademicGroupRequest.isLoading} display={'flex'} alignItems={'center'}>
                        <HStack spacing={'0.5'}>
                            <Input
                                variant={'unstyled'}
                                w={'40px'}
                                p={'1'}
                                color={'gray.500'}
                                textAlign={'end'}
                                type={'number'}
                                value={quota}
                                border={'1px'} borderColor={'transparent'} rounded={'sm'}
                                onInput={event => onInputQuota(event.target['value'])}
                                onFocus={event => event.target.select()}
                                onBlur={event => {
                                    const value = event.target.value
                                    if (+value !== +academicGroup['quota'])
                                        onUpdateAcademicGroup({quota: event.target.value})
                                }}
                                _focus={{
                                    textAlign: 'center',
                                    color: 'blue.500',
                                    background: 'white',
                                    borderColor: 'blue.500'
                                }}
                                isDisabled={putAcademicGroupRequest.isLoading}/>
                            <Text color={'gray.400'}>ч.</Text>
                        </HStack>
                    </Skeleton>
                </Tooltip>
                <Tooltip label={isEnrollAvailable ? 'Запись разрешена' : 'Запись запрещена'}>
                    <Skeleton isLoaded={!putAcademicGroupRequest.isLoading} display={'flex'} alignItems={'center'}>
                        <Switch
                            mx={'3'}
                            colorScheme='blue'
                            isChecked={isEnrollAvailable}
                            onChange={event => {
                                const value = event.target.checked

                                setIsEnrollAvailable(value)
                                onUpdateAcademicGroup({isEnrollAvailable: value})
                            }}
                            isDisabled={putAcademicGroupRequest.isLoading}/>
                    </Skeleton>
                </Tooltip>
                <Tooltip label={'Удалить группу'}>
                    <Skeleton isLoaded={!putAcademicGroupRequest.isLoading} display={'flex'} alignItems={'center'}>
                        <IconButton
                            size={'sm'}
                            variant={'unstyled'}
                            role={'group'}
                            icon={<RemoveIcon stroke={'gray.200'} transition={'250ms ease'} _groupHover={{stroke: 'red.500'}}/>}
                            bg={'none'}
                            aria-label={'Удалить группу'}
                            onClick={() => removeAcademicGroupController.onOpen()}
                            _hover={{}}
                            _active={{}}
                            isDisabled={putAcademicGroupRequest.isLoading}/>
                    </Skeleton>
                </Tooltip>
            </HStack>
            {removeAcademicGroupController.isOpen &&
                <DeleteAcademicGroupModal
                    controller={removeAcademicGroupController}
                    fksGroup={fksGroup}
                    academicGroup={academicGroup}
                    onDelete={onRemoved}/>
            }
        </>
    )
}
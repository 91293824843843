import NotebookIcon from "../../../components/icons/NotebookIcon";
import ODOFragment from "../../../fragments/odo/ODOFragment";
import PennantIcon from "../../../components/icons/PennantIcon";
import FKSGroupFragment from "../../../fragments/groups/FKSGroupFragment";
import StatisticIcon from "../../../components/icons/StatisticIcon";
import JournalFragment from "../../../fragments/journal/JournalFragment";
import QuotaIcon from "../../../components/icons/QuotaIcon";
import QuotaFragment from "../../../fragments/quota/QuotaFragment";
import ClockIcon from "../../../components/icons/ClockIcon";
import AttendanceFragment from "../../../fragments/attendance/AttendanceFragment";
import StudyIcon from "../../../components/icons/StudyIcon";
import ScorecardFragment from "../../../fragments/scorecard/ScorecardFragment";
import PenIcon from "../../../components/icons/PenIcon";
import EnrollFragment from "../../../fragments/enroll/EnrollFragment";
import React, {useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import SpecializationFragment from "../../../fragments/specialization/SpecializationFragment";
import PingPongIcon from "../../../components/icons/PingPongIcon";

const allNavigations = [
    // {
    //     link: {
    //         navigate: "odo",
    //         label: "Списки ОДО",
    //         icon: <NotebookIcon/>,
    //     },
    //     route: {
    //         path: "odo",
    //         fragment: <ODOFragment/>,
    //     },
    //     roles: ["Admin"]
    // },
    {
        link: {
            navigate: "specializations",
            label: "Специализации",
            icon: <PingPongIcon/>,
        },
        route: {
            path: "specializations",
            fragment: <SpecializationFragment/>,
        },
        roles: ["Admin"]
    },
    {
        link: {
            navigate: "groups",
            label: "Группы",
            icon: <PennantIcon/>,
        },
        route: {
            path: "groups",
            fragment: <FKSGroupFragment/>,
        },
        roles: ["Admin"]
    },
    {
        link: {
            navigate: "quota",
            label: "Квоты",
            icon: <QuotaIcon/>,
        },
        route: {
            path: "quota",
            fragment: <QuotaFragment/>,
        },
        roles: ["Admin"]
    },
    {
        link: {
            navigate: 'journal',
            label: "Журнал",
            icon: <StatisticIcon/>,
        },
        route: {
            path: "journal/*",
            fragment: <JournalFragment/>,
        },
        roles: ["Admin", "Teacher"]
    },
    {
        link: {
            navigate: "attendance",
            label: "Посещаемость",
            icon: <ClockIcon/>,
        },
        route: {
            path: "attendance/*",
            fragment: <AttendanceFragment/>,
        },
        roles: ["Staff", "Teacher"]
    },
    {
        link: {
            navigate: "study",
            label: "Успеваемость",
            icon: <StudyIcon/>,
        },
        route: {
            path: "study",
            fragment: <ScorecardFragment/>,
        },
        roles: ["Student"]
    },
    {
        link: {
            navigate: "enroll",
            label: "Запись",
            icon: <PenIcon/>,
        },
        route: {
            path: "enroll",
            fragment: <EnrollFragment/>,
        },
        roles: ["Student"]
    }
]

export default function useDashboardNavigation(role) {
    const params = useParams()
    const navigations = useMemo(() => role ? allNavigations.filter(navigation => navigation.roles.includes(role)) : allNavigations, [role])
    const [navigation, setNavigation] = useState(null)

    useEffect(() => {
        setNavigation(
            navigations.find(navigation =>
                navigation.link.navigate === params['*'].split('/')['0']
            ) ?? null
        )
    }, [params])

    return {navigation, navigations}
}
import React, {useContext, useEffect, useState} from 'react';
import {Box, Flex, IconButton} from "@chakra-ui/react";
import ScorecardFragment from "../scorecard/ScorecardFragment";
import {SessionContext} from "../../context/SessionContext";
import {DashboardHeaderContext} from "../../screens/dashboard/context/DashboardHeaderContext";
import {FilterContext} from "../../context/FilterContext";
import JournalGroupAccordion from "./components/JournalGroupAccordion";
import {JournalEditableScorecardContext} from "./context/JournalEditableScorecardContext";
import ScorecardFilterPanel from "./components/ScorecardFilterPanel";
import ReportIcon from "../../components/icons/ReportIcon";

export default function JournalFragment(props) {
    //initial contexts
    const session = useContext(SessionContext)
    const {setOnButtonBack} = useContext(DashboardHeaderContext)

    //initial states
    const [editableScorecard, setEditableScorecard] = useState(null)
    const [filter, setFilter] = useState({
        teacherUserId: session.role === "Teacher" ? session.userId : undefined
    })

    //initial effects
    useEffect(() => {
        if (editableScorecard)
            setOnButtonBack(() => _ => setEditableScorecard(null))
    }, [editableScorecard])

    //build view
    return (
        <JournalEditableScorecardContext.Provider value={{editableScorecard, setEditableScorecard}}>
            <FilterContext.Provider value={{filter, setFilter}}>
                <Flex display={editableScorecard ? "none" : "flex"} flexDir={'column'} rowGap={'3'} height={'100%'} overflow={'hidden'} {...props}>
                    <ScorecardFilterPanel
                        initialFilter={filter}
                        onUpdate={filter => setFilter(filter)}
                        paddingX={'3'}/>
                    <Box flex={'1 1 0'} overflow={'hidden'}>
                        <JournalGroupAccordion height={'100%'}/>
                    </Box>
                </Flex>
                {editableScorecard &&
                    <ScorecardFragment
                        initialScorecard={editableScorecard}
                        onUpdate={scorecard => setEditableScorecard(scorecard)}
                        isEditable={true}
                        zIndex={'1000'}/>
                }
            </FilterContext.Provider>
        </JournalEditableScorecardContext.Provider>
    )
}
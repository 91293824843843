import React, {useContext, useEffect, useMemo, useState} from 'react';
import useRequest from "../../hooks/RequestHook";
import {Box, Flex, Heading, Input, InputGroup, InputLeftElement, Skeleton, SkeletonText, Spinner, Text, Tooltip} from "@chakra-ui/react";
import ServerService from "../../services/ServerService";
import {collectPagination} from "../../utils/pagination";
import PaginationBar from "../../components/PaginationBar";
import {Search2Icon} from "@chakra-ui/icons";
import ScorecardFragment from "../scorecard/ScorecardFragment";
import * as moment from 'moment-ru'
import {SessionContext} from "../../context/SessionContext";
import {DashboardHeaderContext} from "../../screens/dashboard/context/DashboardHeaderContext";
import StudentScorecard from "./components/StudentScorecard";
import GroupTabs from "./components/GroupTabs";

const limit = 10

export default function AttendanceFragment(props) {
    //initial contexts
    const session = useContext(SessionContext)
    const {setOnButtonBack} = useContext(DashboardHeaderContext)

    //initial states
    const [groups, setGroups] = useState(null)
    const [groupIdSelected, setGroupIdSelected] = useState(null)
    const [scorecards, setScorecards] = useState(null)
    const [scorecardIdSelected, setScorecardIdSelected] = useState(null)
    const [pagination, setPagination] = useState(null)
    const [searchQuery, setSearchQuery] = useState('')
    const [page, setPage] = useState(1)

    //initial requests
    const getGroupRequest = useRequest(ServerService.getFKSGroups, {
        defaultConfig: {
            params: {
                "teachers.userId": session['userId']
            }
        },
        onPreLoad: () => setGroups(null),
        onSuccess: (response, data) => {
            setGroups(data)
            if (data.length > 0)
                setGroupIdSelected(data[0]["_id"])
        }
    })
    const getScorecardRequest = useRequest(ServerService.getScorecards, {
        defaultConfig: {
            params: {
                'teacher.userId': session['userId'],
                '_limit': limit
            }
        },
        onPreLoad: () => {
            setScorecards(null)
            setPagination(null)
        },
        onSuccess: (response, scorecards) => {
            setScorecards(scorecards)
            setPagination(collectPagination(response.headers))
        }
    })

    //initial actions
    const onSelectScorecardAction = scorecardId => {
        setScorecardIdSelected(scorecardId)
        setOnButtonBack(() => _ => setScorecardIdSelected(null))
    }
    const onUpdateScorecardAction = scorecard => {
        setScorecards(_scorecards =>
            _scorecards.map(_scorecard =>
                _scorecard['_id'] === scorecard['_id'] ? scorecard : _scorecard
            )
        )
    }

    //initial effects
    useEffect(() => {
        getGroupRequest.start()
    }, [])
    useEffect(() => {
        if (groupIdSelected)
            getScorecardRequest.start({
                delay: searchQuery !== "" ? 1250 : 0,
                params: {
                    _page: page,
                    '_search:student.fullName': searchQuery,
                    'fksGroup._id': groupIdSelected
                }
            })
    }, [groupIdSelected, searchQuery, page])

    //initial fragments
    const attendanceFragment = useMemo(() => {
        return groups?.length > 0
            ? <Flex
                height={'100%'}
                overflow={'hidden'}
                flexDirection='column'
                rowGap={{base: '3', lg: '5'}}
                {...props}>
                <Skeleton isLoaded={groups} height={groups ? 'max-content' : '45px'}>
                    {groups && groupIdSelected &&
                        <Flex alignItems={'center'} justifyContent={'space-between'}>
                            <Heading color={'gray.600'} fontSize={{md: '24px'}}
                                     textTransform={'uppercase'}>{groups.find(group => group['_id'] === groupIdSelected)['specialization']['name']}</Heading>
                            <Heading color={'gray.600'} fontSize={{md: '24px'}} textTransform={'uppercase'} textAlign={'end'}>{moment().format('DD MMMM YYYY г.')}</Heading>
                        </Flex>
                    }
                </Skeleton>
                <Skeleton isLoaded={groups} height={groups ? 'max-content' : '45px'}>
                    {groups && groupIdSelected &&
                        <GroupTabs
                            groups={groups}
                            groupIdSelected={groupIdSelected}
                            onSelect={groupId => setGroupIdSelected(groupId)}/>}
                </Skeleton>
                <Skeleton isLoaded={groups} height={groups ? 'max-content' : '45px'}>
                    <Box marginX={'3'}>
                        <InputGroup>
                            <InputLeftElement pointerEvents='none'>
                                {getScorecardRequest.isLoading
                                    ? <Spinner
                                        thickness='3px'
                                        speed='0.65s'
                                        emptyColor='gray.200'
                                        color='#00487a'
                                        size='sm'
                                    />
                                    : <Search2Icon color='gray.300'/>
                                }
                            </InputLeftElement>
                            <Input
                                type='text'
                                placeholder='Поиска студента'
                                value={searchQuery}
                                onInput={event => setSearchQuery(event.target['value'])}
                                color='gray.500'
                                background={'white'}/>
                        </InputGroup>
                    </Box>
                </Skeleton>
                <SkeletonText
                    isLoaded={scorecards}
                    noOfLines={limit + 2}
                    skeletonHeight={'10'}
                    flex={'1 1 1'}
                    overflow={'hidden'}
                    overflowY={scorecards ? 'auto' : 'hidden'}
                    paddingX={'3'}>
                    <Flex flexDir={'column'} rowGap={'1.5'}>
                        {scorecards?.map(scorecard =>
                            <StudentScorecard
                                key={scorecard['_id']}
                                scorecard={scorecard}
                                onUpdate={onUpdateScorecardAction}
                                onClick={() => onSelectScorecardAction(scorecard['_id'])}
                            />
                        )}
                    </Flex>
                </SkeletonText>
                {pagination &&
                    <Flex
                        zIndex='100'
                        paddingX={5}
                        alignItems={'center'}
                        justifyContent={'space-between'}>
                        <Tooltip label={'Общее колличество студентов'} placement={"end"}>
                            <Text
                                paddingX={'3'}
                                paddingY={'1'}
                                color={'gray.400'}
                                fontSize={'sm'}
                                fontWeight={'thin'}
                                border={'1px'} borderColor={'gray.300'}
                                rounded={'md'}
                                userSelect={'none'}>
                                {`${pagination['totalCount']} чел.`}
                            </Text>
                        </Tooltip>
                        <PaginationBar
                            pagination={pagination}
                            onPageSelected={page => setPage(page)}/>
                    </Flex>
                }
            </Flex>
            : <Text color={'gray.400'} fontSize={'md'}>{"Вы не ведете ни одной группы"}</Text>

    }, [groups, scorecards, searchQuery])
    const scorecardFragment = useMemo(() => {
        const scorecard = scorecards?.find(({_id}) => _id === scorecardIdSelected)

        if (scorecard)
            return (
                <ScorecardFragment
                    initialScorecard={scorecard}
                    onUpdate={onUpdateScorecardAction}
                    isEditable={true}
                    {...props}/>
            )
        else {
            setScorecardIdSelected(null)
            return undefined
        }
    }, [scorecardIdSelected])

    //initial view
    return scorecardIdSelected ? scorecardFragment : attendanceFragment
}
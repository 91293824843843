import React, {useCallback, useEffect, useRef, useState} from 'react';
import {
    Button,
    Flex,
    FormControl,
    FormLabel, Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text, useAlertStyles,
    useToast
} from "@chakra-ui/react";
import useRequest from "../../../hooks/RequestHook";
import ServerService from "../../../services/ServerService";
import SpecializationSelect from "./SpecializationSelect";

export default function CreateFKSGroupModal({controller, specializations = [], onCreated = group => undefined, onCancel = () => undefined, ...props}) {
    //initial components
    const toast = useToast()

    //initial refs
    const numberRef = useRef()

    //initial states
    const [number, setNumber] = useState('100')
    const [specialization, setSpecialization] = useState(specializations[0] ?? null)

    //initial requests
    const postGroupRequest = useRequest(ServerService.postFKSGroup, {})

    //initial actions
    const onCreateGroup = useCallback(() => {
        postGroupRequest.start({data: {number, specializationId: specialization['_id'], teachers: []}}, {
            onSuccessCallBack: (response, _id) => {
                controller.onClose()
                onCreated({_id, number, specialization, teachers: []})
                toast({
                    title: `Группа ${number} добавлена`,
                    position: 'top-right',
                    status: 'success',
                    isClosable: true,
                })
            },
            onErrorCallBack: (error) => (
                toast({
                    title: `Ошибка добавления группы ${number}`,
                    description: error?.response?.data ?? "Неизвестная ошибка",
                    position: 'top-right',
                    status: 'error',
                    isClosable: true,
                })
            )
        })
    }, [number, specialization])

    //initial effects
    useEffect(() => {
        if (specializations.length < 1) {
            controller.onClose()
            toast({
                title: `Список специализаций пуст`,
                description: 'Добавьте специализации и попробуйте еще раз',
                position: 'top-right',
                status: 'error',
                isClosable: true,
            })
        }
    }, [])

    //build view
    return (
        specializations.length > 0 &&
        <Modal
            initialFocusRef={numberRef}
            closeOnOverlayClick={false}
            returnFocusOnClose={false}
            isOpen={controller.isOpen}
            onClose={controller.onClose}
            {...props}>
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader userSelect={'none'}>Добавление группы</ModalHeader>
                <ModalCloseButton onClick={onCancel} isDisabled={postGroupRequest.isLoading}/>
                <ModalBody pb={6} userSelect={'none'}>
                    <FormControl>
                        <FormLabel userSelect={'none'}>Номер</FormLabel>
                        <Input
                            ref={numberRef}
                            placeholder='Введите номер'
                            value={number}
                            onInput={event => setNumber(event.target['value'])}
                            isDisabled={postGroupRequest.isLoading}/>
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel userSelect={'none'}>Специализация</FormLabel>
                        <SpecializationSelect
                            specializations={specializations}
                            specializationIdSelected={specialization['_id']}
                            onSelect={specialization => setSpecialization(specialization)}
                            isDisabled={postGroupRequest.isLoading}/>
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <Button
                        colorScheme='blue'
                        width={'100%'}
                        mr={3}
                        onClick={() => onCreateGroup()}
                        isLoading={postGroupRequest.isLoading}>Добавить</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
import React, {useCallback} from 'react';
import {Button, Flex, Highlight, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useToast} from "@chakra-ui/react";
import useRequest from "../../../hooks/RequestHook";
import ServerService from "../../../services/ServerService";

export default function DeleteSpecializationModal({specialization, controller, onDelete = (specialization) => undefined, onCancel = () => undefined, ...props}) {
    //initial components
    const toast = useToast()

    //initial requests
    const deleteSpecializationRequest = useRequest(ServerService.deleteSpecialization, {
        onError: () => (
            toast({
                title: specialization['name'],
                description: `Ошибка удаления специализации`,
                position: 'top-right',
                status: 'error',
                isClosable: true,
            })
        )
    })

    //initial actions
    const onDeleteSpecialization = useCallback(() => {
        deleteSpecializationRequest.start({id: specialization['_id']}, {
            onSuccessCallBack: (response, data) => {
                controller.onClose()
                onDelete(specialization)
                toast({
                    title: specialization['name'],
                    description: `Специализация удалена`,
                    position: 'top-right',
                    status: 'success',
                    isClosable: true,
                })
            }
        })
    }, [specialization])
    const onCancelModal = useCallback(() => {
        controller.onClose()
        onCancel()
    }, [specialization])

    //build view
    return (
        <Modal
            closeOnOverlayClick={false}
            returnFocusOnClose={false}
            isOpen={controller.isOpen}
            onClose={controller.onClose}
            {...props}>
            <ModalOverlay/>
            <ModalContent>
                <ModalHeader userSelect={'none'}>Удаление специализации</ModalHeader>
                <ModalBody pb={6} userSelect={'none'}>
                    <Highlight
                        query={specialization['name']}
                        styles={{
                            px: '1',
                            color: 'white',
                            fontWeight: 'medium',
                            bg: 'red.500',
                            rounded: '5',
                        }}>
                        {`Вы действительно хотите удалить специализацию ${specialization['name']} ?`}
                    </Highlight>
                </ModalBody>
                <ModalFooter>
                    <Button
                        colorScheme='red'
                        width={'100%'}
                        mr={3}
                        onClick={onDeleteSpecialization}
                        isLoading={deleteSpecializationRequest.isLoading}>Удалить</Button>
                    <Button
                        width={'100%'}
                        onClick={onCancelModal}
                        isDisabled={deleteSpecializationRequest.isLoading}>Отмена</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
import React from "react";
import {Button, Flex, IconButton, Input, Select, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tooltip, Tr} from "@chakra-ui/react";
import * as moment from "moment-ru";
import RemoveIcon from "../../../components/icons/RemoveIcon";

export default function ScorecardAttendanceList({attendance, onUpdate = (attendance) => undefined, isEditable = false, isDisabled = false, ...props}) {
    const onDateChangeAction = (visit, date) => onUpdate(attendance.map(_visit => _visit['date'] === visit['date'] ? {...visit, date} : _visit))
    const onGradeChangeAction = (visit, gradeStr) => {
        const grade = gradeStr === '' ? 0 : parseInt(gradeStr, 10)

        if (!isNaN(grade) && grade >= 0 && grade <= 100)
            onAttendanceUpdateAction({...visit, grade})
    }
    const onPresenceChangeAction = (visit, presence) => onAttendanceUpdateAction({...visit, presence})
    const onVisitAddAction = () => {
        let date = null
        let plusDays = 0

        do {
            date = moment().add(plusDays, 'days')
            plusDays++
        } while (attendance.find(visit => moment(visit['date']).isSame(date, 'day')))

        onUpdate([...attendance, {date: date.format('YYYY-MM-DD'), grade: 0, presence: 'Присутствовал'}])
    }
    const onVisitRemoveAction = visit => onUpdate(attendance.filter(_visit => _visit['date'] !== visit['date']))
    const onAttendanceUpdateAction = visit => onUpdate(attendance.map(_visit => _visit['date'] === visit['date'] ? visit : _visit))

    return (
        <Flex
            flexDir={'column'}
            gap={'3'}
            background={'white'}
            border={'1px'} borderColor={'gray.200'}
            rounded={'md'}
            {...props}>
            {attendance && attendance.length > 0
                ? <TableContainer>
                    <Table size='sm'>
                        <Thead>
                            <Tr>
                                <Th paddingY={'3'}>№</Th>
                                <Th paddingY={'3'}>Дата</Th>
                                <Th paddingY={'3'}>Баллы</Th>
                                <Th paddingY={'3'}>Посещение</Th>
                                <Th paddingY={'3'}></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {attendance?.map((visit, index) =>
                                <Tr key={visit['date']}>
                                    <Td>{`${index + 1}.`}</Td>
                                    <Td>{
                                        isEditable
                                            ? <Input
                                                type={'date'}
                                                value={visit['date'] ? visit['date'] : moment().format('YYYY-MM-DD')}
                                                onChange={event => onDateChangeAction(visit, event.target['value'])}
                                                isDisabled={isDisabled}
                                                color={'gray.500'}
                                                background={'white'}
                                                onClick={event => event.target.showPicker()}/>
                                            : <Text>{moment(visit['date']).format("DD MMMM YYYY г. (dddd)")}</Text>
                                    }</Td>
                                    <Td>{
                                        isEditable
                                            ? <Input
                                                type='number'
                                                placeholder='0'
                                                value={visit['grade']}
                                                onChange={event => onGradeChangeAction(visit, event.target.value)}
                                                size='sm'
                                                step='10' min='0' max='100'
                                                isDisabled={isDisabled}
                                            />
                                            : visit['grade']
                                    }</Td>
                                    <Td>{
                                        isEditable
                                            ? <Select
                                                size='sm'
                                                value={visit['presence']}
                                                onChange={event => onPresenceChangeAction(visit, event.target.value)}
                                                isDisabled={isDisabled}>
                                                <option value='Присутствовал'>Присутствовал</option>
                                                <option value='Ув. Причина'>Ув. Причина</option>
                                            </Select>
                                            : visit['presence']
                                    }</Td>
                                    <Td display={'flex'} justifyContent={'center'}>
                                        {isEditable &&
                                            <Tooltip label={`Удалить посещение ${moment(visit['date']).format("DD MMMM YYYY г. (dddd)")}`}>
                                                <IconButton
                                                    role={'group'}
                                                    variant={'unstyled'}
                                                    icon={(
                                                        <RemoveIcon
                                                            stroke={'gray.100'}
                                                            _groupHover={{stroke: 'red.500'}}
                                                            transition={'150ms ease'}/>
                                                    )}
                                                    onClick={() => onVisitRemoveAction(visit)}
                                                    aria-label={`Удалить посещение`}
                                                    background={'none'}
                                                    isDisabled={isDisabled}/>
                                            </Tooltip>
                                        }
                                    </Td>
                                </Tr>
                            )}
                        </Tbody>
                    </Table>
                </TableContainer>
                : <Text color={'gray.400'} fontSize={'md'} margin={'auto'} padding={'3'}>Нет посещенных занятий</Text>
            }
            {isEditable &&
                <Button w={'max-content'} mx={'3'} my={'3'} alignSelf={'end'} colorScheme={'blue'} onClick={onVisitAddAction}>Добавить посещение</Button>
            }
        </Flex>
    )
}
import React, {useContext} from "react";
import {Box} from "@chakra-ui/react";
import {Navigate, Route, Routes} from "react-router-dom";
import {DashboardNavigationContext} from "../context/DashboardNavigationContext";

export default function DashboardContent(props) {
    const {navigations} = useContext(DashboardNavigationContext)

    return <Box
        height={'100%'}
        overflow={'hidden'}
        overflowY={'auto'}
        paddingY={'5'}
        {...props}>
        <Routes>
            {navigations?.map(({route}) =>
                <Route
                    key={route.path}
                    path={route.path}
                    element={route.fragment}/>
            )}
            {navigations && navigations.length > 0 &&
                <Route
                    path={'*'}
                    element={
                        <Navigate
                            to={navigations[0].link.navigate}
                            replace/>
                    }
                />
            }
        </Routes>
    </Box>
}
import {useCallback, useRef, useState} from "react";

export default function useRequest(
    request,
    settings = {
        defaultConfig: {
            params: {},
            delay: 0
        },
        onPreLoad: () => undefined,
        onPostLoad: () => undefined,
        onHandle: (response, data) => data,
        onSuccess: (response, data) => undefined,
        onError: (error) => undefined,
    }
) {
    const controllerRef = useRef(null)

    const [data, setData] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)

    const start = useCallback((
        initialConfig = {},
        callbacks = {
            onPreLoadCallBack: () => undefined,
            onPostLoadCallBack: () => undefined,
            onSuccessCallBack: (response, data) => undefined,
            onErrorCallBack: (error) => undefined
        }
    ) => {
        const controller = new AbortController()
        const config = {
            ...settings.defaultConfig,
            ...initialConfig,
            params: {
                ...settings.defaultConfig?.params,
                ...initialConfig?.params
            },
            signal: controller.signal
        }

        controllerRef.current?.abort()
        controllerRef.current = controller

        const requestHandler = _ => {
            if (controller.signal.aborted)
                return

            setData(null)
            setError(null)
            setIsLoading(true)

            settings.onPreLoad && settings.onPreLoad()
            callbacks.onPreLoadCallBack && callbacks.onPreLoadCallBack()

            request(config)
                .then(response => {
                    if (!controller.signal.aborted) {
                        const data = response.data
                        const handledData = settings.onHandle ? settings.onHandle(response, data) : data

                        setData(handledData)

                        settings.onSuccess && settings.onSuccess(response, handledData)
                        callbacks.onSuccessCallBack && callbacks.onSuccessCallBack(response, handledData)
                    }
                })
                .catch(error => {
                    if (!controller.signal.aborted) {
                        setError(error)
                        settings.onError && settings.onError(error)
                        callbacks.onErrorCallBack && callbacks.onErrorCallBack(error)
                    }
                })
                .finally(_ => {
                    setIsLoading(false)

                    if (!controller.signal.aborted) {
                        settings.onPostLoad && settings.onPostLoad()
                        callbacks.onPostLoadCallBack && callbacks.onPostLoadCallBack()
                    }
                })
        }
        config.delay > 100 ? setTimeout(requestHandler, config.delay) : requestHandler()
    }, [])

    return {data, isLoading, error, start, controllerRef}
}
import React, {useState} from 'react';
import useRequest from "../hooks/RequestHook";
import ServerService from "../services/ServerService";
import SearchInput from "./SearchInput";
import {
    Box,
    Divider,
    Popover,
    PopoverAnchor,
    PopoverBody,
    PopoverContent,
    Stack,
    Text,
    useDisclosure
} from "@chakra-ui/react";

export default function SearchTeacherAutocomplete({initialMatch = "", onSelect = teacher => undefined, ...props}) {
    //initial props
    const {onOpen, onClose, isOpen} = useDisclosure()

    //initial states
    const [match, setMatch] = useState(initialMatch)
    const [teachers, setTeachers] = useState(null)

    //initial requests
    const searchTeacherRequest = useRequest(ServerService.getTeachers, {
        defaultConfig: {
            delay: 1000
        },
        onPreLoad: () => setTeachers(null),
        onSuccess: (response, teachers) => setTeachers(teachers),
        onPostLoad: () => onOpen()
    })

    //initial actions
    const onTeacherInputAction = value => {
        const query = value.trim()
        setMatch(query)

        if (query.length > 1)
            searchTeacherRequest.start({params: {'_search:fullName': query}})

        onClose()
    }
    const onTeacherSelectAction = teacher => {
        setMatch(teacher.fullName)
        onSelect(teacher)
        onClose()
    }

    //initial view
    return (
        <Popover
            isLazy
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            closeOnBlur={false}
            autoFocus={false}
            placement='bottom-start'>
            <PopoverAnchor>
                <Box {...props}>
                    <SearchInput
                        value={match}
                        onInput={onTeacherInputAction}
                        placeholder={'Введите преподавателя'}
                        isLoading={searchTeacherRequest.isLoading}/>
                </Box>
            </PopoverAnchor>
            <PopoverContent>
                <PopoverBody padding={'0'}>
                    <Stack
                        spacing={'0'}
                        divider={<Divider/>}
                        maxHeight={'300px'}
                        overflowX={'hidden'} overflowY={'auto'}>
                        {teachers?.length > 0
                            ? teachers?.map(teacher =>
                                <Text
                                    key={teacher['userId']}
                                    padding={'3'}
                                    color={'gray.500'}
                                    fontSize={'sm'}
                                    userSelect={'none'}
                                    cursor={'pointer'}
                                    onClick={() => onTeacherSelectAction(teacher)}
                                    _hover={{background: 'gray.100'}}
                                >{teacher.fullName}</Text>)
                            : <Text
                                marginX={'auto'}
                                padding={'3'}
                                color={'gray.300'}
                                fontSize={'sm'}
                                userSelect={'none'}
                            >Преподаватели не найдены</Text>
                        }
                    </Stack>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
}
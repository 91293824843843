import React, {useEffect, useState} from 'react';
import {Button, Flex, Grid, Heading, HStack, Radio, RadioGroup, Spinner, Stack, Text, useToast} from "@chakra-ui/react";
import useRequest from "../../hooks/RequestHook";
import ServerService from "../../services/ServerService";
import {shortUserName} from "../../utils/environments";
import {useNavigate} from "react-router-dom";

export default function EnrollFragment(props) {
    //initial components
    const navigate = useNavigate()
    const toast = useToast()

    //initial states
    const [specializations, setSpecializations] = useState(null)
    const [specializationIdSelected, setSpecializationIdSelected] = useState(null)

    //Initialize requests
    const getSpecializationRequest = useRequest(ServerService.getSpecializationEnrollQuotes, {
        onPreLoad: () => setSpecializations(null),
        onSuccess: (response, specializations) => setSpecializations(specializations)
    })
    const postEnrollRequest = useRequest(ServerService.postEnroll, {
        onSuccess: () => navigate('study'),
        onError: (event) => toast({
            title: `Ошибка записи на дисциплину`,
            description: event.message,
            position: 'top-right',
            status: 'error',
            isClosable: true,
        })
    })

    //initial actions
    const onEnrollSubmit = () => {
        const formData = new FormData()
        formData.append('specializationId', specializationIdSelected)

        postEnrollRequest.start({data: formData})
    }

    //Initialize effects
    useEffect(() => {
        getSpecializationRequest.start()
    }, [])

    //initial view
    return getSpecializationRequest.isLoading
        ? <Flex width={'100%'} height={'100%'}>
            <Spinner
                margin={'auto'}
                size='xl'
                thickness='10px'
                speed='0.65s'
                emptyColor='gray.200'
                color='blue.500'/>
        </Flex>
        : <>
            {getSpecializationRequest.error
                ? <Text color={'gray.400'} fontSize={'md'}>{"Вы уже записаны на дисциплину"}</Text>
                : <Flex flexDir={'column'} rowGap={'8'} {...props}>
                    <Heading size={'lg'} color={'gray.700'} userSelect={'none'}>Выберите специализацию</Heading>
                    <RadioGroup name={'specialization'}>
                        <Grid gridTemplateColumns={{base: '1f', lg: 'repeat(3, 1fr)'}} gridAutoRows={'90px'} gridGap={'3'}>
                            {specializations?.length > 0
                                ? specializations?.map(({specialization, teachers, isEnrollAvailable}) => {
                                    const isSelected = specialization['_id'] === specializationIdSelected
                                    const isDisabled = !isEnrollAvailable || postEnrollRequest.isLoading || teachers.length < 1

                                    return <HStack
                                        key={specialization['_id']}
                                        as={'label'}
                                        overflow={'hidden'}
                                        minWidth={'100%'}
                                        minHeight={'100%'}
                                        paddingX={'5'}
                                        spacing={'5'}
                                        background={'white'}
                                        border={'1px'} borderColor={isSelected && isEnrollAvailable ? 'blue.500' : 'gray.200'} borderRadius={'lg'}
                                        cursor={!isDisabled ? 'pointer' : 'not-allowed'}
                                        userSelect={'none'}
                                        transition={'ease 100ms'}>
                                        <Radio
                                            isChecked={isSelected}
                                            value={specialization['_id']}
                                            isDisabled={isDisabled}
                                            onChange={() => !isDisabled && setSpecializationIdSelected(specialization['_id'])}/>
                                        <Stack spacing={'0.5'} flex={'1 1 0'}>
                                            <Text
                                                color={!isDisabled ? (isSelected ? 'blue.500' : 'gray.600') : 'gray.300'}
                                                fontSize={'lg'}
                                                fontWeight={'medium'}
                                                transition={'ease 100ms'}>{specialization['name']}</Text>
                                            <Text color={!isDisabled ? 'gray.400' : 'gray.300'} fontSize={'xs'}>
                                                {teachers?.length > 0
                                                    ? teachers
                                                        ?.map(teacher => shortUserName(teacher))
                                                        ?.join(' ')
                                                    : "Преподаватели отсутствуют"
                                                }
                                            </Text>
                                        </Stack>
                                    </HStack>
                                })
                                : <Text color={'gray.300'} fontSize={'sm'} textAlign={'center'} textTransform={'uppercase'}>Список специализаций пуст</Text>
                            }
                        </Grid>
                    </RadioGroup>
                    {specializations?.find(specialization => specialization['isEnrollAvailable'] === true) &&
                        <Button
                            width={'max-content'}
                            marginStart={'auto'}
                            paddingX={'8'} paddingY={'4'}
                            colorScheme={'blue'}
                            isDisabled={!specializationIdSelected || postEnrollRequest.isLoading}
                            isLoading={postEnrollRequest.isLoading}
                            onClick={onEnrollSubmit}>Записаться</Button>
                    }
                </Flex>
            }
        </>
}
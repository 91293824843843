import React, {useEffect, useState} from 'react';
import {Divider, SkeletonText, Stack, Text} from "@chakra-ui/react";
import useRequest from "../../hooks/RequestHook";
import ServerService from "../../services/ServerService";
import SpecializationCollapse from "./components/SpecializationCollapse";

export default function QuotaFragment(props) {
    //initial states
    const [specializations, setSpecializations] = useState(null)

    //initial requests
    const getSpecializationsRequest = useRequest(ServerService.getSpecializations, {
        onPreLoad: () => setSpecializations(null),
        onSuccess: (response, specializations) => {
            setSpecializations(
                specializations?.sort((x, y) => x['name'] > y['name'] ? 1 : -1)
            )
        }
    })

    //initial effects
    useEffect(() => {
        getSpecializationsRequest.start()
    }, [])

    //initial view
    return (specializations
            ? <Stack
                overflowX={'hidden'} overflowY={'auto'}
                spacing={'0'}
                divider={<Divider/>}
                rounded={'md'}
                background={'white'}
                border={'1px'} borderColor={'gray.200'}
                {...props}>
                {specializations?.length > 0
                    ? specializations?.map((specialization, index) => (
                        <SpecializationCollapse
                            key={specialization['_id']}
                            specialization={specialization}
                            index={index}/>
                    ))
                    : <Text color={'gray.300'} fontSize={'sm'} textAlign={'center'} textTransform={'uppercase'}>Список специализаций пуст</Text>
                }
            </Stack>
            : <SkeletonText skeletonHeight={'10'} noOfLines={15} flex={'1 1 0'} overflowX={'hidden'} overflowY={'auto'}/>
    )
}
import {useState} from "react";

function useDashboardHeaderHook() {
    const [title, setTitle] = useState("")
    const [subTitle, setSubTitle] = useState("")
    const [onButtonBack, setOnButtonBack] = useState(null)

    return {title, setTitle, subTitle, setSubTitle, onButtonBack, setOnButtonBack}
}

export default useDashboardHeaderHook;
import React, {Children} from 'react';
import {Divider, Grid, GridItem} from "@chakra-ui/react";

export const SimpleTable = ({children, ...props}) => {
    return <Grid
        gridTemplateColumns="max-content 1fr"
        gridAutoRows='max-content'
        gridColumnGap={20}
        gridRowGap={2}
        alignItems='center'
        {...props}
    >
        {Children.map(children, (child, index) =>
            <>
                {child}
                {index < children.length - 1 && <GridItem colSpan={2}><Divider/></GridItem>}
            </>
        )}

    </Grid>
};

export const SimpleTableItem = ({label, cell}) => {
    return <>
        <GridItem color="gray.400" fontSize="0.75rem" textTransform='uppercase'>{label}</GridItem>
        <GridItem color="gray.700" fontSize="0.9rem">{cell}</GridItem>
    </>
}
import React from 'react';
import {Icon} from "@chakra-ui/react";

const DatabaseIcon = (props) => (
    <Icon xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" strokeWidth="2" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round" {...props}>
        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
        <ellipse cx="12" cy="6" rx="8" ry="3"></ellipse>
        <path d="M4 6v6a8 3 0 0 0 16 0v-6"/>
        <path d="M4 12v6a8 3 0 0 0 16 0v-6"/>
    </Icon>
)

export default DatabaseIcon;